import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import {searchClearText, SearchInput, searchLoadText} from "../../search";
import AutoRules from "../../components/AutoRules";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";


const TaboolaCampaigns = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;
  const navigate = useNavigate();
  const searchStorage = TaboolaCampaigns.name
  const [load, setLoad] = useState(false)

  const [titleAdvertiser, setTitleAdvertiser] = useState("");
  const [campaigns, setCampaigns] = useState([])
  const [myCampaigns, setMyCampaigns] = useState([])

  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);

  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_campaigns()
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    searchClearText('TaboolaSites');
  }, []);


  const get_campaigns = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/taboola/advertisers/${cid}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setTitleAdvertiser(response.data.advertiser_name)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })

    axios.get(`${process.env.REACT_APP_API_URL}/api/taboola/campaigns/?advertiser=${cid}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setMyCampaigns(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/taboola/statistic_by_campaign/?advertiser=${cid}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setCampaigns(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/taboola/campaign/${params.row.campaign_id}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "conversion",
      headerName: "Conversion",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "cpa",
      headerName: "CPA",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "id",
      align: 'center',
      headerAlign: 'center',
      headerName: "Auto Rules",
      flex: 0.1,
      aggregable: false,
      sortable: false,
      renderCell: (params) => {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
        const editAutoRule = params.row.campaign_created === null || new Date(params.row.campaign_created) < twoDaysAgo

        if (params.rowNode?.type !== 'pinnedRow' && editAutoRule) {
          return (
            <AutoRules
              get_campaigns={get_campaigns}
              myCampaigns={myCampaigns}
              campaign_id={params.row.campaign_id}
              campaignTitle={params.row.campaign_name}
              with_auto_rules={params.row.with_auto_rules}
              endpoint={`/api/taboola/campaigns/${params.row.campaign_id}/auto_rules/`}
            />
          );
        }
        return null;
      }
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Advertiser ${titleAdvertiser || ""}`} subtitle="statistic by campaigns"/>

      <Box m="0 0 20px 0">
        <Link to={`/`} onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '0 0 15px', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          getRowId={(row) => row.campaign_id}
          initialState={{
            aggregation: {
              model: {
                spend: 'sum',
                revenue: 'sum',
                profit: 'sum',
              },
            },
          }}
          density="compact"
          hideFooterSelectedRowCount
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TaboolaCampaigns);
