import {useEffect, useState} from "react"
import {Autocomplete, Box, Button, IconButton, Modal, TextField, useTheme} from "@mui/material"
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

import {tokens} from "../../theme";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";


const CrossNewCampaign = (props) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [categories, setCategoties] = useState([])
  const [requestData, setRequestData] = useState({})

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const styleModal = {
    ...getModalCommonStyle(),
    width: 400,
  };

  const get_categories = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/cross/categories/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setCategoties(response.data)
        } else {
          toast.error(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
  }
  useEffect(() => {
    get_categories()
  }, [])

  const setData = (name, value) => {
    setRequestData(prevState => ({
      ...prevState, [name]: value
    }))
  }

  const check_domain = () => {
    if (!requestData.domain) {
      toast.error("Enter domain name");
      return
    }

    axios.get(`${process.env.REACT_APP_API_URL}/api/cross/campaigns/check_domain/?domain=${requestData.domain}`, requestConfig)
      .then(function (response) {
        if (response.data) {
          toast.success('Great! Domain is available!')
        } else {
          toast.error("Sorry, domain is not available. Try another one.")
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
  }

  const onClose = () => {
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const onSubmit = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/cross/campaigns/`, requestData, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          toast.success("Campaign created successfully");
          props.get_campaigns()
        } else {
          toast.error(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
  }

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={() => setOpenCreate(true)}>Create new Campaign</Button>
      </Box>

      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >

        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Create campaign" subtitle="create new campaign in Cross"/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required id="outlined-basic" label="Campaign name" variant="outlined"
                     sx={{width: 300, marginTop: 3}}
                     onChange={(event) => setData('name', event.target.value)}/>

          <TextField required id="outlined-basic" label="Domain" variant="outlined" sx={{width: 300, marginTop: 3}}
                     onChange={(event) => setData('domain', event.target.value)}/>
          <Box display="flex" justifyContent="start" mt="5px">
            <Button color="success" variant="contained" size="small" onClick={check_domain}>
              Check domain availability
            </Button>
          </Box>

          <Autocomplete
            required={true}
            disableClearable
            options={categories}
            getOptionLabel={(option) => option.category_name}
            sx={{width: 300, marginTop: 3}}
            onChange={(event, values) => setData('category', values.category_name)}
            renderInput={(params) => <TextField {...params} label="Choose offer's category"/>}
          />

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit}>
              Create New Campaign
            </Button>
          </Box>

        </Box>

      </Modal>
    </Box>
  )
}

export default CrossNewCampaign
