import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

// Check if REACT_APP_API_URL contains 'localhost'
const isStrictModeEnabled = process.env.REACT_APP_API_URL.includes('localhost');

root.render(
  isStrictModeEnabled ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);
