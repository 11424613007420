import {useEffect, useState} from "react";
import axios from "axios";
import {Box, Button, Grid, IconButton, Modal, Switch, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";

import {tokens} from "../../theme";
import Header from "../../components/Header";
import {getModalCommonStyle, requestConfig} from "../utils";

const ModalRulesList = (props) => {
  const advertiser_id = props.advertiser_id;
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [flag, setFlag] = useState(true);
  const [rulesList, setRulesList] = useState([{}]);

  const styleModal = {
    ...getModalCommonStyle(),
    width: '50%',
  };

  const getRules = () => {
    const data = {advertiser_id: advertiser_id};
    axios.get(`${process.env.REACT_APP_API_URL}/api/tiktok/rules/`, {params: data, ...requestConfig})
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          setRulesList(response.data);
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText);
      });
  };

  const updateRule = (id, status, id_rule, advertiser) => {
    let new_status = !status;
    const data = {
      status_rule: new_status,
      id_rule: id_rule,
      advertiser: advertiser
    };
    axios.patch(`${process.env.REACT_APP_API_URL}/api/tiktok/rules/${id}/`, data, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const status = response.data.status_rule;
          toast.success(`Status set ${status ? "ACTIVE" : "DISABLE"}`);
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText);
      });
    setFlag(!flag);
    getRules();
    rulesListContent();
  };

  const deleteRule = (id, id_rule, advertiser) => {
    const data = {
      id_rule: id_rule,
      advertiser: advertiser
    };
    axios.delete(`${process.env.REACT_APP_API_URL}/api/tiktok/rules/${id}/`, {data, ...requestConfig})
      .then(function (response) {
        console.log(response.data.status_rule);
        console.log(response.data);
        if (response.status === 200) {
          const status = response.data.status_rule;
          toast.success(`Status set ${status ? "ACTIVE" : "DISABLE"}`);
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText);
      });
    setFlag(!flag);
    getRules();
    rulesListContent();
  };

  const rulesListContent = () => (
    rulesList.map((rule) =>
      (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={2}>
            {rule.name_rule}
          </Grid>
          <Grid item xs={2}>
            {rule.status_rule ? 'ACTIVE' : "DISABLE"}
          </Grid>
          <Grid item xs={2}>
            {rule.status_rule
              ? <Switch defaultChecked
                        onChange={() => updateRule(rule.id, rule.status_rule, rule.id_rule, rule.advertiser)}/>
              : <Switch onChange={() => updateRule(rule.id, rule.status_rule, rule.id_rule, rule.advertiser)}/>}
          </Grid>
          <Grid item xs={2}>
            <Button color="secondary" variant="contained" size="small" sx={{marginTop: '10px'}}
                    onClick={() => deleteRule(rule.id, rule.id_rule, rule.advertiser)}>
              Delete
            </Button>
          </Grid>
        </Grid>
      )
    )
  );

  const onClose = () => {
    setOpenModal(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    getRules();
  }, [flag]);
  useEffect(() => {
    rulesListContent();
  }, [rulesList, flag]);

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={() => setOpenModal(true)}>Automated Rules Control</Button>
      </Box>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Automated Rules" subtitle={`control automated rule for Advertiser ID ${advertiser_id}`}/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          {rulesListContent()}
        </Box>

      </Modal>
    </Box>
  );
};

export default ModalRulesList;
