import {useState} from "react";
import axios from "axios";
import {Box, Button, Grid, IconButton, Modal, TextField, Typography, useTheme} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";

import {tokens} from "../../theme";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";


const ModalCampaignEdit = (props) => {
  const [campaignData, setCampaignData] = useState({})
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sources = props.sources
  const domains = props.domains
  const offers = props.offers


  const setData = (name, value, index) => {
    if (index === undefined) {
      setCampaignData(prevState => ({
        ...prevState, [name]: value
      }))
      console.log(campaignData)
    } else {
      let prevState = campaignData
      prevState.splitstream[index][name] = value
      setCampaignData({...prevState})
      console.log(campaignData)
    }
  }

  const styleModal = {
    ...getModalCommonStyle(),
    width: '85%',
  };

  const get_campaign_data = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/campaigns/${props.campaign_id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setCampaignData(response.data);
        } else {
          toast.error(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText)
      })
  };

  const onClose = () => {
    setCampaignData({})
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const onSubmit = async () => {
    const anyOfferEmpty = campaignData.splitstream.some(offer => !offer.offer__id_offer);

    if (anyOfferEmpty) {
      console.log(campaignData)
      toast.error('Choose valid offer in "Split settings" section.')
      return
    }

    setButtonCreateDisabled(true);
    try {
      axios.put(`${process.env.REACT_APP_API_URL}/api/redtrack/campaigns/${props.campaign_id}/`, campaignData, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            setOpenCreate(false);
            toast.success(response.data, {position: "top-center", autoClose: 3000});
            props.get_campaigns()
            setCampaignData({})
          } else {
            toast.error(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        })
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  const handleClick = async () => {
    await props.get_references('domains');
    await props.get_references('offers');
    await props.get_references('sources');
    get_campaign_data();
    setOpenCreate(true);
  }

  const base_context = () => {
    if (campaignData.title !== undefined) {
      const default_source = () => {
        let element
        sources.map((item) => {
          if (item.id_source === campaignData.id_source) {
            element = item
          }
        })
        return element
      }

      const default_domain = () => {
        let current_domain
        domains.map((item) => {
          if (item.domain_id === campaignData.id_domain) {
            current_domain = item
          }
        })
        return current_domain
      }

      return (
        <Box>
          <TextField required id="outlined-basic" label="Campaign's title" variant="outlined"
                     sx={{width: '100%', marginTop: 3}}
                     defaultValue={campaignData.title}
                     onChange={(event) => setData('title', event.target.value)}/>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Autocomplete
                required={true}
                disableClearable
                id="combo-box-source"
                options={sources}
                defaultValue={default_source()}
                getOptionLabel={(option) => option.title + ' (#' + option.id + ')'}
                sx={{width: '100%', marginTop: 3}}
                onChange={(event, values) => setData('id_source', values.id_source)}
                renderInput={(params) => <TextField {...params} label="Choose source"/>}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                required={true}
                disableClearable
                id="combo-box-domain"
                options={domains}
                defaultValue={default_domain()}
                getOptionLabel={(option) => option.domain_name}
                sx={{width: '100%', marginTop: 3}}
                onChange={(event, values) => setData('id_domain', values.domain_id)}
                renderInput={(params) => <TextField {...params} label="Choose domain"/>}
              />
            </Grid>
          </Grid>

        </Box>
      )
    }
  }

  const split_context = () => {
    if (campaignData.splitstream !== undefined) {
      const default_offer = (id_offer) => {
        let element
        offers.map((item) => {
          if (item.id_offer === id_offer) {
            element = item
          }
        })
        return element
      }

      const delete_offer = (index) => {
        let current_data = campaignData
        current_data['splitstream'].splice(index, 1)
        setCampaignData({...current_data})
      }

      const add_offer = () => {
        let current_data = campaignData
        current_data['splitstream'].push({offer__title: '', offer__id_offer: '', weight: 100})
        setCampaignData({...current_data})
      }

      return (
        <Box>
          {campaignData.splitstream.map((item, index) => (
            <Grid container rowSpacing={2} columnSpacing={1} alignItems="center">
              <Grid item xs={8}>
                <Autocomplete
                  required={true}
                  disableClearable
                  id="combo-box-offer"
                  options={offers}
                  defaultValue={default_offer(item.offer__id_offer)}
                  getOptionLabel={(option) => option.title + ' (#' + option.id + ')'}
                  sx={{width: '100%', marginTop: 3}}
                  onChange={(event, values) => {
                    setData('offer__title', values.title, index);
                    setData('offer__id_offer', values.id_offer, index)
                  }}
                  renderInput={(params) => <TextField {...params} label="Choose offer"/>}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField required id="outlined-basic" label="Weight" variant="outlined"
                           sx={{width: '100%', marginTop: 3}}
                           defaultValue={item.weight}
                           onChange={(event) => setData('weight', event.target.value, index)}/>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="delete" sx={{width: '100%', marginTop: 3}}>
                  <DeleteIcon onClick={() => delete_offer(index)}/>
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Box display="flex" justifyContent="start" mt="10px">
            <Button color="success" variant="contained" size="small" onClick={add_offer}>
              Add offer
            </Button>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="outlined" onClick={handleClick}>Edit</Button>
      </Box>
      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Edit campaign" subtitle=''/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            {/* Left side */}
            <Grid item xs={6}>
              <Typography variant='h5' m='10px' color={colors.blueAccent[500]}>
                Base settings
              </Typography>
              {base_context()}
            </Grid>
            {/* Right side */}
            <Grid item xs={6}>
              <Typography variant='h5' m='10px' color={colors.blueAccent[500]}>
                Split settings
              </Typography>
              {split_context()}
            </Grid>
          </Grid>

          {/* <TextField required id="outlined-basic" label="Edit title" variant="outlined" sx={{ width: 630, marginTop: 3}}
              defaultValue={props.offer_data.title}
              onChange={(event) => setData('title', event.target.value)}/>

          <TextField required id="outlined-basic" label="Keywords" variant="outlined" sx={{ width: 630, marginTop: 3}}
              defaultValue={props.offer_data.keywords}
              onChange={(event) => setData('keywords', event.target.value)}/> */}

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ModalCampaignEdit;
