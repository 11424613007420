import React, {useEffect, useRef, useState} from 'react';
import {useDebounce} from 'use-debounce';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const searchPathPrefix = 'searchText_'

const searchClearAll = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key.startsWith(searchPathPrefix)) {
      sessionStorage.removeItem(key);
    }
  }
};

const searchClearText = (storagePath) => {
  if (storagePath) {
    sessionStorage.removeItem(`${searchPathPrefix}${storagePath}`);
  }
};

const searchSaveText = (storagePath, searchText) => {
  if (storagePath) {
    sessionStorage.setItem(`${searchPathPrefix}${storagePath}`, searchText);
  }
};

const searchLoadText = (storagePath) => {
  return storagePath ? sessionStorage.getItem(`${searchPathPrefix}${storagePath}`) || '' : '';
};

const SearchInput = ({storagePath = '', onChange, ...props}) => {
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(storagePath);
  });
  const [clearImmediately, setClearImmediately] = useState(false);
  const [debouncedSearchText] = useDebounce(searchText, clearImmediately ? 0 : 750);
  const [prevSearchText, setPrevSearchText] = useState(searchText);
  const [inputValue, setInputValue] = useState(prevSearchText);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    searchSaveText(storagePath, debouncedSearchText);
    setPrevSearchText(debouncedSearchText);
    if (clearImmediately) {
      setSearchText('');
      setClearImmediately(false);
    }
  }, [debouncedSearchText, clearImmediately]);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setSearchText(value);
  };

  const handleClear = () => {
    setInputValue('');
    setSearchText('');
    setClearImmediately(true);
  };

  useEffect(() => {
    setInputValue(prevSearchText);
    if (onChange) {
      onChange(prevSearchText);
    }
  }, [prevSearchText]);

  return (
    <TextField
      label="Search"
      size="small"
      variant="outlined"
      value={inputValue}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} size="small" style={{outline: 'none'}}>
              <ClearIcon style={{color: '#999'}}/>
            </IconButton>
          </InputAdornment>
        ),
        style: {
          color: isFocused ? undefined : '#F08080',
        },
        onFocus: () => {
          inputRef.current && inputRef.current.select(); // Select all text when focused
          setIsFocused(true);
        },
        onBlur: () => setIsFocused(false),
        inputRef: inputRef,
      }}
      {...props}
    />
  );
};

export {searchLoadText, searchSaveText, searchClearText, searchClearAll, SearchInput};
