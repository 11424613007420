import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from "axios";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {requestConfig} from '../utils';

const FacebookCallback = ({ isAuthenticated }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Facebook Connect
    const sendCode = () => {
      if (searchParams.get('code')) {
        axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/connect/?code=${searchParams.get('code')}`, requestConfig)
          .then(function (response) {
            if (response.status === 200) {
              toast.success("Connection is successful. Please, wait for redirect ...");
              navigate('/facebook/adaccounts/');
            } else if (response.status === 204) {
              // TODO: Need return correct error message from API
              toast.error(response.data ? response.data : 'Invalid verification code format');
              navigate('/facebook/adaccounts/');
            }
          })
          .catch(function (error) {
            console.log(error);
            toast.error('An error occurred while connecting to Facebook.');
            navigate('/facebook/adaccounts/');
          });
      }
    };

    useEffect(() => {
      sendCode();
    }, [searchParams]);

    return (
        <div style={{padding: '0 16px'}}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          <span style={{marginLeft: '10px'}}>Connection is in progress. Please, wait for redirect ...</span>
        </div>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(FacebookCallback);
