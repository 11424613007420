import {useEffect, useState} from "react";
import axios from "axios";
import {Box, Button, Grid, IconButton, Modal, TextField, Typography, useTheme} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";

import {tokens} from "../../theme";
import Header from "../../components/Header";
import {getModalCommonStyle, requestConfig} from "../utils";

const ModalRules = (props) => {
  const advertiser_id = props.advertiser_id
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const styleModal = {
    ...getModalCommonStyle(),
    width: '80%',
  };

  // Create apply_objects
  const [apply_objects, setApply_Objects] = useState([{
    dimension: "ADGROUP",
    dimension_ids: [],
    pre_condition_type: "ALL_ACTIVE_AD_GROUP",
    bind_type: 'BIND'
  }])

  const changeApply_Objects = (e) => {
    let value = e.target.value ? e.target.value : e.target.outerText;
    let currentApply_Objects = apply_objects
    currentApply_Objects[0][e.target.name] = value;
    setApply_Objects(currentApply_Objects)
  }

  // Create conditions
  const objectConditions = {
    subject_type: 'COST',
    range_type: 'YESTERDAY',
    match_type: 'GT',
    values: 20,
    calculation_type: 'OF_EACH_OBJECT'
  }
  const [rangeConditions, setRangeConditions] = useState(1)
  const [all_conditions, setConditions] = useState([objectConditions])

  useEffect(() => {
    console.log(rangeConditions)
    let array = all_conditions ? all_conditions : [];
    for (let i = 0; i < rangeConditions; i++) {
      array[i] ? console.log('q') : array[i] = (objectConditions)
    }
    console.log(array)
    setConditions(array)
    conditionsContent()
  }, [rangeConditions, all_conditions])

  const conditionsContent = () => {
    let content = [];
    for (let i = 0; i < rangeConditions; i++) {
      content.push(
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={2.4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={params_conditions.subject_type}
              sx={{width: '100%', marginTop: 3}}
              onChange={(event) => {
                event.target.name = 'subject_type';
                event.target.index = i;
                changeConditions(event)
              }}
              renderInput={(params) => <TextField {...params} label="Subject Type"/>}
            />
          </Grid>
          <Grid item xs={2.4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={params_conditions.range_type}
              sx={{width: '100%', marginTop: 3}}
              onChange={(event) => {
                event.target.name = 'range_type';
                event.target.index = i;
                changeConditions(event)
              }}
              renderInput={(params) => <TextField {...params} label="Range Type"/>}
            />
          </Grid>
          <Grid item xs={2.4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={params_conditions.match_type}
              sx={{width: '100%', marginTop: 3}}
              onChange={(event) => {
                event.target.name = 'match_type';
                event.target.index = i;
                changeConditions(event)
              }}
              renderInput={(params) => <TextField {...params} label="Match Type"/>}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField required id="outlined-basic" label="Value" variant="outlined" sx={{width: '100%', marginTop: 3}}
                       name='values' onChange={(event) => {
              event.target.index = i;
              changeConditions(event)
            }}/>
          </Grid>
          <Grid item xs={2.4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={params_conditions.calculation_type}
              sx={{width: '100%', marginTop: 3}}
              onChange={(event) => {
                event.target.name = 'calculation_type';
                event.target.index = i;
                changeConditions(event)
              }}
              renderInput={(params) => <TextField {...params} label="Calculation Type"/>}
            />
          </Grid>

        </Grid>
      )
    }
    return content
  }

  const changeConditions = (e) => {
    let value = e.target.value ? e.target.value : e.target.outerText;
    let currentConditions = all_conditions;
    currentConditions[e.target.index][e.target.name] = value;
    console.log(currentConditions)
    setConditions(currentConditions)
  }

  // Create actions
  const [all_actions, setActions] = useState([{
    subject_type: 'TURN_OFF',
    action_type: 'DECREASE',
    value_type: 'EXACT'
  }])

  const changeActions = (e) => {
    let value = e.target.value ? e.target.value : e.target.outerText;
    let currentActions = all_actions;
    currentActions[0][e.target.name] = value;
    setActions(currentActions)
    // setActions([{ ...all_actions[0], [e.target.name]: value }]);
    console.log(all_actions)
  }

  ////////////////////////
  const [formData, setFormData] = useState({
    advertiser_id: advertiser_id,
    name: 'New Rule',
    apply_objects: apply_objects,
    conditions: all_conditions,
    actions: all_actions,
    notification: {
      notification_type: 'ANY_CHANGES',
      email_setting: {
        email_exec_time: [],
        no_result_notification: false,
        notification_period: 'EVERY_TIME'
      }
    },
    rule_exec_info: {
      exec_time_type: 'PER_HALF_HOUR',
      // exec_time: '15:00'
    }
  })

  const onChange = (e) => {
    let value = e.target.value ? e.target.value : e.target.outerText;
    setFormData({...formData, [e.target.name]: value});
    console.log(formData)
    console.log(formData.actions)
  }

  ////////////////
  const onClose = () => {
    setOpenModal(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const onSubmit = () => {
    const data = formData
    axios.post(`${process.env.REACT_APP_API_URL}/api/tiktok/rules/`, data, requestConfig)
      .then(function (response) {
        if (response.status === 201) {
          setOpenModal(false);
          toast.success('Success! Tracker link will be create in 5-20 seconds', {
            position: "top-center",
            autoClose: 3000
          });
        } else {
          toast.error(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText)
      })
  };

  const params = {
    dimension: ['ADGROUP'],
    pre_condition_type: ['ALL_ACTIVE_AD_GROUP'],
  }

  const params_conditions = {
    subject_type: ['COST', 'COST_PER_RESULT'],
    range_type: ['TODAY'],
    match_type: ['GT'],
    calculation_type: ['OF_EACH_OBJECT']
  }

  const params_actions = {
    subject_type: ['TURN_OFF']
  }

  return (
    <Box>
      <Box m="0 0 0 0">
        <Button variant="contained" onClick={() => setOpenModal(true)}>Create new Automated Rules</Button>
      </Box>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Automated Rules"
                    subtitle={`create new automated rule for Advertiser ID ${props.advertiser_id}`}/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required id="outlined-basic" label="Name of rule" variant="outlined"
                     sx={{width: 280, marginTop: 3}}
                     name='name' onChange={(event) => onChange(event)}/>

          <Typography style={{marginTop: '15px'}}>Apply objects (к чему применять действие)</Typography>
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="left"
            spacing={2}
          >
            <Grid item xs={2.4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={params.dimension}
                // getOptionLabel={(option) => option.dimension}
                sx={{width: '100%', marginTop: 3}}
                onChange={(event) => {
                  event.target.name = 'dimension';
                  changeApply_Objects(event)
                }}
                renderInput={(params) => <TextField {...params} label="Objects (dimension)"/>}
              />
            </Grid>
            <Grid item xs={2.4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={params.pre_condition_type}
                // getOptionLabel={(option) => option.dimension}
                sx={{width: '100%', marginTop: 3}}
                onChange={(event) => {
                  event.target.name = 'pre_condition_type';
                  changeApply_Objects(event)
                }}
                renderInput={(params) => <TextField {...params} label="Pre condition"/>}
              />
            </Grid>
          </Grid>

          {/* ----- Conditions ----- */}
          <Typography style={{marginTop: '15px'}}>Add conditions (условия срабатывания)</Typography>
          {conditionsContent()}
          <Button color="secondary" variant="contained" size="small" sx={{marginTop: '10px'}} onClick={() => {
            let newRange = rangeConditions + 1;
            setRangeConditions(newRange);
          }}>
            Add new condition
          </Button>

          {/* ----- ACTIONS ----- */}
          <Typography style={{marginTop: '15px'}}>Actions (действия, что будет происходить)</Typography>
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="left"
            spacing={2}
          >
            <Grid item xs={2.4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={params_actions.subject_type}
                sx={{width: '100%', marginTop: 3}}
                onChange={(event) => {
                  event.target.name = 'subject_type';
                  changeActions(event)
                }}
                renderInput={(params) => <TextField {...params} label="Type action"/>}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit}>
              Create New Rule
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  )
}

export default ModalRules;
