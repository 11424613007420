import React, {useEffect, useState} from "react";
import axios from "axios";
import {connect, useSelector} from "react-redux";
import {Box, Button, Tab, Tabs} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {useActiveTabHistory} from '../../hocs/ActiveTabHistory';

const SessionStatisticByPublisher = ({isAuthenticated}) => {
  const searchStorage = SessionStatisticByPublisher.name;
  const [load, setLoad] = useState(false);

  const [statisticsAds, setStatisticsAds] = useState([]);

  const [activeTab, setActiveTab] = useActiveTabHistory('tab_ads', SessionStatisticByPublisher.name);
  const [sortModelAds, setSortModelAds] = useState([]);
  const [rowCountAds, setRowCountAds] = useState(0);
  const [paginationModelAds, setPaginationModelAds] = useState({pageSize: 100, page: 0});
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setPaginationModelAds(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_statistics();
  }, [paginationModelAds, sortModelAds, activeTab]);

  const get_statistics = () => {
    if (activeTab === 'tab_ads') {
      const ordering = convertSortModelToDjangoSort(sortModelAds).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/bad_publishers/?page=${paginationModelAds.page + 1}&page_size=${paginationModelAds.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setStatisticsAds(results);
            setRowCountAds(count);
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }
  }

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }

    setActiveTab(newValue);

    if (newValue !== 'tab_ads') {
      setStatisticsAds([]);
    }
    // Add similar conditions for future tabs, if necessary
    // if (newValue !== 'tab_another') {
    //   setStatisticsAnother([]);
    // }
  };

  const columns_ads = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.1,
    },
    {
      field: "account",
      headerName: "Account",
      flex: 0.1,
    },
    {
      field: "ref_pubsite",
      headerName: "Publisher",
      flex: 0.3,
    },
    {
      field: "ref_adnetwork",
      headerName: "Ad Network",
      flex: 0.15,
    },
    {
      field: "session_count",
      headerName: "Sessions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "total_visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "total_clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
  ];

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Bad Publishers" subtitle="statistic by publishers"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 0 0', minHeight: '38px', width: '100%'}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value);
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={get_statistics}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Tabs for ADS Publishers and others"
            sx={{marginTop: '10px'}}
          >
            <Tab value="tab_ads" label="ADS"/>
            {/* Other Tabs here */}
            {/* <Tab value="another_tab" label="Another Tab" /> */}
          </Tabs>

          <TabPanel value="tab_ads"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              rows={statisticsAds}
              columns={columns_ads}
              rowCount={rowCountAds}
              getRowId={(row) => `${row.ref_adnetwork}-${row.date}-${row.ref_pubsite}-${row.account}`}
              sortingMode="server"
              onSortModelChange={(newModel) => {
                setSortModelAds(newModel);
              }}
              pagination
              paginationMode="server"
              paginationModel={paginationModelAds}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelAds(newModel);
              }}
              density="compact"
              disableAggregation
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnMenu={true}
            />
          </TabPanel>


        </TabContext>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(SessionStatisticByPublisher);
