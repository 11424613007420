import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {connect} from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {green, red} from "@mui/material/colors";
import {SearchInput, searchLoadText} from "../../search";
import ModalNewDomain from "./ModalNewDomain";
import {convertSortModelToDjangoSort, getDataGridCommonStyle, requestConfig} from "../utils";
import Header from "../../components/Header";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const DomainsMain = ({isAuthenticated}) => {
  const searchStorage = DomainsMain.name;
  const [domains, setDomains] = useState([]);
  const [load, setLoad] = useState(false);
  const [searchText, setSearchText] = useState(() => searchLoadText(searchStorage));
  const [sortModel, setSortModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({pageSize: 100, page: 0});
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const get_domains = () => {
    setLoad(true);
    const ordering = convertSortModelToDjangoSort(sortModel).join(",");

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/domains/domains/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&search=${searchText}&ordering=${ordering}`,
        requestConfig
      )
      .then((response) => {
        if (response.status === 200) {
          const {results, count} = response.data;
          setDomains(results || []);
          setRowCount(count || 0);
        } else {
          console.log(response.statusText);
        }
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        setDomains([]);
        setRowCount(0);
      });
  };

  useEffect(() => {
    get_domains();
  }, [searchText, paginationModel.page, paginationModel.pageSize, sortModel]);

  const columns = [
    {
      field: "is_active",
      headerName: "Status",
      flex: 0.2,
      renderCell: (params) => (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {params.value ? (
            <>
              <CheckCircleIcon style={{color: green[500], marginRight: '4px'}}/>
              <span style={{color: green[500]}}>Active</span>
            </>
          ) : (
            <>
              <CancelIcon style={{color: red[500], marginRight: '4px'}}/>
              <CircularProgress size={20} sx={{marginRight: '4px'}}/>
              <span style={{color: red[500]}}>In Process</span>
            </>
          )}
        </Box>
      ),
    },
    {
      field: "domain_name",
      headerName: "Domain Name",
      flex: 0.5,
    },
    {
      field: "registrator",
      headerName: "Registrator",
      flex: 0.4,
    },
    {
      field: "ad_system",
      headerName: "Ad System",
      flex: 0.3,
    },
    {
      field: "account",
      headerName: "Account",
      flex: 0.2,
    },
  ];

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Domains" subtitle="created new domains"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value);
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <ModalNewDomain get_domains={get_domains}/>

        <Box sx={{flex: 1}}/>

        <Button
          sx={{padding: '7px', minWidth: 0}}
          variant="contained"
          onClick={get_domains}
        >
          <RefreshIcon/>
        </Button>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        ...getDataGridCommonStyle(),
      }}>
        <DataGrid
          rows={domains}
          columns={columns}
          rowCount={rowCount}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(DomainsMain);
