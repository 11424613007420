import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";

import axios from "axios";
import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import 'reactjs-popup/dist/index.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";


const FacebookAdgroup = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;
  const navigate = useNavigate();
  const [load, setLoad] = useState(false)

  const [campaigns, setCampaigns] = useState([])

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_campaigns()
  }, [startStr, endStr, selectedEmployee]);


  const get_campaigns = () => {
    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/statistic_by_adgroup/?campaign_id=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setCampaigns(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columns = [
    {
      field: "adgroup_name",
      headerName: "Adgroup Name",
      flex: 0.3, aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "conversion",
      headerName: "Conversion",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "cpa",
      headerName: "CPA",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Campaign ${campaigns.at(0)?.campaign_name || ""}`} subtitle="statistic by adgroups"/>

      <Box m="0 0 20px 0">
        <Link to={`/`} onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>

        <Box sx={{flex: 1}}/>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          getRowId={(row) => row.adgroup_id + '-' + row.adgroup_name + '-' + row.campaign_name}
          initialState={{
            aggregation: {
              model: {
                spend: 'sum',
                revenue: 'sum',
                profit: 'sum',
              },
            },
          }}
          density="compact"
          hideFooterSelectedRowCount
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(FacebookAdgroup);
