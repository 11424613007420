import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {connect, useSelector} from 'react-redux';
import {Box, Button} from "@mui/material";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {convertSortModelToDjangoSort, getDataGridCommonStyle, ModalCircularProgress, requestConfig} from "../utils";
import ModalNewDomain from "./ModalNewDomain";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {activeTabHistoryClear} from "../../hocs/ActiveTabHistory";

const AdsDomains = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const searchStorage = AdsDomains.name;
  const [load, setLoad] = useState(false);

  const [domains, setDomains] = useState([]);

  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });
  const [openCreate, setOpenCreate] = useState(false);

  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    activeTabHistoryClear('AdsOneDomainStatistic')
  }, []);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [searchText, selectedEmployee]);

  useEffect(() => {
    get_domains();
  }, [paginationModel, sortModel]);


  const get_domains = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true);
    axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/domains/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count} = response.data;
          setDomains(results);
          setRowCount(count);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columns = [
    {
      field: "domain_name",
      headerName: "Domain Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/ads/statistic/${params.row.id}`}>{params.value}</Link>
        );
      }
    },
  ];

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Ads" subtitle="list of domains"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value);
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <ModalNewDomain open={openCreate} handleClose={() => setOpenCreate(false)} handleRefresh={get_domains}/>

        <Button variant="contained" onClick={() => setOpenCreate(true)} sx={{marginLeft: '20px'}}>Add Domain</Button>

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_domains()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={domains}
          columns={columns}
          rowCount={rowCount}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(AdsDomains);
