import {Avatar, Box, Divider, Menu, MenuItem, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {logout} from '../actions/auth';
import {tokens} from "../theme";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {useNavigate} from "react-router-dom";

const Topbar = ({logout, isAuthenticated, user}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [isAuthenticated]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout_user = () => {
    logout();
    navigate("/");
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{position: "absolute", right: "24px"}}>

      {/* Icons */}
      <Box display="flex" alignItems="center" ml="auto">
        {isAuthenticated && user.name && (
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              color={colors.grey[100]}
              fontWeight="bold"
              onClick={handleMenuOpen}
              sx={{cursor: 'pointer', mr: 2, fontSize: '1.25rem'}}
            >
              {user.name}
            </Typography>
            <Avatar
              onClick={handleMenuOpen}
              sx={{
                cursor: 'pointer',
                bgcolor: colors.greenAccent[500],
                color: colors.primary[900],
                width: 40,
                height: 40
              }}
            >
              {user.name.charAt(0)}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={handleMenuClose}
              MenuListProps={{
                onMouseLeave: handleMenuClose,
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate(`/profile/${user.id}/`);
                  handleMenuClose();
                }}
              >
                <AccountBoxIcon sx={{mr: 1}}/>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {user.email}
                </Typography>
              </MenuItem>
              <Divider/>
              <MenuItem
                onClick={() => {
                  logout_user();
                  handleMenuClose();
                }}
              >
                <LogoutIcon sx={{mr: 1}}/>
                Exit
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {logout})(Topbar);
