import {Link, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import axios from 'axios';

import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";

import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";

const SystemOneDomainStatistics = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;

  const [statistics, setStatistics] = useState([]);
  const [titleDomain, setTitleDomain] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [load, setLoad] = useState(false);

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const navigate = useNavigate();


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_domains();
  }, [startStr, endStr, selectedEmployee]);


  const get_domains = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/system1/domains/${cid}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setTitleDomain(response.data.domain_name);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setLoad(true);
    axios.get(`${process.env.REACT_APP_API_URL}/api/system1/statistics_by_date/?domain=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setStatistics(response.data);
        } else {
          console.log(response.status);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  }

  const columns = [
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "domain_name",
      headerName: "Domain Name",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Domain ${titleDomain}`} subtitle="statistic by domain"/>

      <Box m="0 0 20px 0">
        <Link to="/" onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={statistics}
          columns={columns}
          getRowId={(row) => row.date}
          initialState={{
            aggregation: {
              model: {
                visits: 'sum',
                clicks: 'sum',
                revenue: 'sum',
                epc: 'avg',
              },
            },
            sorting: {
              sortModel: sortModel,
            },
          }}
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          density="compact"
          hideFooterSelectedRowCount={true}
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(SystemOneDomainStatistics);
