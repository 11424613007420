import {useState} from "react";
import axios from "axios";
import {Box, Button, Grid, IconButton, Modal, TextField, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";

import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";

const ModalEditSource = (props) => {
  const [conversions, setConversions] = useState([]);

  const [sourceTitle, setSourceTitle] = useState('');
  const [sourcePixel, setSourcePixel] = useState('');
  const [sourceToken, setSourceToken] = useState('');
  const [sourceEventsData, setSourceEventsData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const default_event = {conversion: 'conversion', event: 'Purchase'};

  const styleModal = {
    ...getModalCommonStyle(),
    width: '60%',
  };

  const get_source_data = async (source_id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/sources/${source_id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setSourceTitle(response.data.name);
          if (response.data.integrations.length > 0) {
            setSourcePixel(response.data.integrations[0].integration_setting.pixel_id);
            setSourceToken('');
            if (response.data.integrations[0].conversions_events.length > 0) {
              let event_data = [];
              setSourceEventsData([]);
              response.data.integrations[0].conversions_events.forEach((element) => {
                event_data = [...event_data, {
                  conversion: element.conversion.index,
                  event: element.event,
                }];
              });
              setSourceEventsData(event_data);
            } else {
              setSourceEventsData([default_event]);
            }
          } else {
            setSourcePixel('');
            setSourceToken('');
            setSourceEventsData([default_event]);
          }
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.message);
      });
  };

  const load_references = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/conversions/`, requestConfig)
      .then(function (response) {
        setConversions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const runModalWindow = async () => {
    await load_references();
    await get_source_data(props.source_id);
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const onSubmit = async () => {
    const data = {
      name: sourceTitle,
      pixel: sourcePixel,
      token: sourceToken,
      conversions_events: sourceEventsData,
    };

    setButtonCreateDisabled(true);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/clickflare/sources/${props.source_id}/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setShowModal(false);
            toast.success('Success!', {position: "top-center", autoClose: 3000});
            props.get_campaigns();
          } else {
            toast.error(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        });
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  const base_context = () => {
    return (
      <Box>
        <TextField
          required
          label="Traffic Source Name"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setSourceTitle(event.target.value)}
          value={sourceTitle}
        />
        <TextField
          required
          label="Pixel ID"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setSourcePixel(event.target.value)}
          value={sourcePixel}
        />

        <TextField
          required
          label="Token"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setSourceToken(event.target.value)}
          value={sourceToken}
        />
      </Box>
    );
  };

  const event_context = () => {
    const set_data = (index, name, value) => {
      let prevState = [...sourceEventsData];
      prevState[index][name] = value;
      setSourceEventsData(prevState);
    };

    const delete_row = (index) => {
      let prevState = [...sourceEventsData];
      prevState.splice(index, 1);
      setSourceEventsData(prevState);
    };

    const add_row = () => {
      if (sourceEventsData.length > 0) {
        setSourceEventsData([...sourceEventsData, {...sourceEventsData[sourceEventsData.length - 1]}]);
      } else {
        setSourceEventsData([...sourceEventsData, default_event]);
      }
    };

    return (
      <Box>
        {sourceEventsData.map((item, index) => (
          <Grid container rowSpacing={2} columnSpacing={1} key={index}>
            <Grid item xs={5.5}>
              <Autocomplete
                sx={{width: '100%', marginTop: 2}}
                disableClearable
                options={conversions}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  set_data(index, 'conversion', value.index);
                }}
                renderInput={(params) => <TextField {...params} label="Choose conversion name" />}
                value={conversions.find(conversion => conversion.index === sourceEventsData[index].conversion)}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Autocomplete
                sx={{width: '100%', marginTop: 2}}
                disableClearable
                options={props.source_events}
                onChange={(event, value) => {
                  set_data(index, 'event', value);
                }}
                renderInput={(params) => <TextField {...params} label="Choose event name"/>}
                value={props.source_events.find(event => event === sourceEventsData[index].event)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" sx={{width: '100%', marginTop: 3}} disabled={sourceEventsData.length <= 1}>
                <DeleteIcon onClick={() => delete_row(index)}/>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Box justifyContent="flex-end" mt="15px">
          <Button color="success" variant="contained" size="small" onClick={add_row} disabled={sourceEventsData.length >= 3}>
            Add event
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="outlined" onClick={runModalWindow}>Edit</Button>
      </Box>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Edit traffic source" subtitle={sourceTitle}/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h5' mt='10px'>
                Base settings
              </Typography>
              {base_context()}
            </Grid>

            <Grid item xs={6}>
              <Typography variant='h5' mt='10px'>
                Event settings
              </Typography>
              {event_context()}
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalEditSource;
