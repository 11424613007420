import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { LineChart } from '@mui/x-charts/LineChart';

export const LineChartComponent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <LineChart
            sx={{
                '.MuiLineElement-root, .MuiMarkElement-root': {
                display: 'none',},
                '& .MuiAreaElement-series-Germany': {
                    fill: "url('#4158d0')",
                  },
                width: '100%'
            }}
            leftAxis={null}
            bottomAxis={null}
            xAxis={[
                {
                id: 'Day',
                data: props.date,
                scaleType: 'time',
                valueFormatter: (date) => date.toDateString(),
                },
            ]}
            series={[
                {
                data: props.data,
                area: true,
                color: '#4158d0'
                },
            ]}
            // width={350}
            height={120}
            margin={{ top: 20, left: 0, right: 0, bottom: 20 }}
    />
    )
}
