import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import axios from "axios";
import {Box, List, ListItem, ListItemText, Typography, useTheme} from "@mui/material";

import Header from "../../components/Header";
import {tokens} from "../../theme";
import {AuthContext} from '../../hocs/AuthContext';


const Profile = () => {
  const {cid} = useParams();

  const [userInfo, setUserInfo] = useState({
    id: [],
    name: [],
    user_id: [],
    tiktok_advertisers: [],
    facebook_ad_accounts: [],
    sedo_domains: [],
    tracker_domains: [],
  })

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {isAuthenticated, loading} = useContext(AuthContext);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
      'Accept': 'application/json'
    }
  };

  const getUserInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/user_profile/?id=${cid}`, config);
      if (response.status === 200) {
        setUserInfo(response.data.at(0));
      } else {
        console.log(response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (!loading && isAuthenticated === false) {
      navigate("/");
    }
  }, [isAuthenticated, loading, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfo();
    }
  }, [isAuthenticated, cid]);

  const showListItem = (title, currentArray) => (
    <ListItem disablePadding>
      <ListItemText
        primary={`${title}: `}
        secondary={currentArray.map((item, index) => (
          <Box key={index}>
            <Typography
              sx={{display: 'inline'}}
              component="span"
              variant="body2"
              color="text.primary"
            >
              - {item}
            </Typography>
          </Box>
        ))}
      />
    </ListItem>
  );

  return (
    <Box>
      <Header title="Profile" subtitle={`user ID - ${userInfo.user_id}, user name - ${userInfo.name}`}/>
      <List sx={{width: '100%', maxWidth: 460}}>
        <ListItem disablePadding>
          <ListItemText primary={`${userInfo.name}, ID - ${userInfo.user_id}`}/>
        </ListItem>
        {showListItem("Tiktok Advertisers", userInfo.tiktok_advertisers)}
        {showListItem("Facebook AD Accounts", userInfo.facebook_ad_accounts)}
        {showListItem("Sedo Domains", userInfo.sedo_domains)}
        {showListItem("Tracker Domains", userInfo.tracker_domains)}
      </List>
    </Box>
  );
};

export default Profile;
