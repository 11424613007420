import {combineReducers} from "redux";
import auth from "./auth";
import date from "./date";
import employee from "./employee";

export default combineReducers({
  auth: auth,
  date: date,
  employee: employee,
});
