import {useState} from "react";
import axios from "axios";
import {Box, Button, Grid, IconButton, Modal, TextField, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";

import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";

const ModalEditCampaign = (props) => {
  const [offers, setOffers] = useState([]);
  const [sources, setSources] = useState([]);
  const [domains, setDomains] = useState([]);

  const [campaignTitle, setCampaignTitle] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignSourceId, setCampaignSourceId] = useState('');
  const [campaignDomainId, setCampaignDomainId] = useState('');
  const [campaignSplitData, setCampaignSplitData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const default_split = {offer_id: null, weight: 100};

  const styleModal = {
    ...getModalCommonStyle(),
    width: '80%',
  };

  const get_campaign_data = async (campaign_id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/campaigns/${campaign_id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setCampaignTitle(response.data.name);
          setCampaignName(response.data.name);
          setCampaignSourceId(response.data.traffic_source.id);
          setCampaignDomainId(response.data.domain.id);

          let split_data = [];
          setCampaignSplitData([]);
          response.data.campaigns_offers.forEach((element, index) => {
            split_data = [...split_data, {
              offer_id: element.offer_id,
              weight: element.weight
            }];
          });
          setCampaignSplitData(split_data);
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const load_references = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/reference/`, requestConfig)
      .then(function (response) {
        setOffers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/sources/reference/`, requestConfig)
      .then(function (response) {
        setSources(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/domains/`, requestConfig)
      .then(function (response) {
        setDomains(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const runModalWindow = async () => {
    await load_references();
    await get_campaign_data(props.campaign_id);

    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const onSubmit = async () => {
    const data = {
      name: campaignName,
      traffic_source_id: campaignSourceId,
      domain_id: campaignDomainId,
      campaigns_offers: campaignSplitData,
    };

    setButtonCreateDisabled(true);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/clickflare/campaigns/${props.campaign_id}/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setShowModal(false);
            toast.success('Success!', {position: "top-center", autoClose: 3000});
            props.get_campaigns();
          } else {
            toast.error(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        });
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  const base_context = () => {
    return (
      <Box>
        <TextField
          required label="Title" variant="outlined" sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setCampaignName(event.target.value)}
          value={campaignName}
          />

        <Autocomplete
          sx={{width: '90%', marginTop: 2}}
          disableClearable
          options={sources}
          getOptionLabel={(option) => option.name + ' (#' + option.id + ')'}
          onChange={(event, values) => setCampaignSourceId(values.id)}
          renderInput={(params) => <TextField {...params} label="Choose traffic source"/>}
          value={sources.find(traffic_sources => traffic_sources.id === campaignSourceId)}
        />

        <Autocomplete
          sx={{width: '90%', marginTop: 2}}
          disableClearable
          options={domains}
          getOptionLabel={(option) => option.domain}
          onChange={(event, values) => setCampaignDomainId(values.id)}
          renderInput={(params) => <TextField {...params} label="Choose domain"/>}
          value={domains.find(domain => domain.id === campaignDomainId)}
        />
      </Box>
    );
  };

  const split_context = () => {
    const set_data = (index, name, value) => {
      let prevState = [...campaignSplitData];
      prevState[index][name] = value;
      setCampaignSplitData(prevState);
    };

    const delete_row = (index) => {
      let prevState = [...campaignSplitData];
      prevState.splice(index, 1);
      setCampaignSplitData(prevState);
    };

    const add_row = () => {
      if (campaignSplitData.length > 0) {
        setCampaignSplitData([...campaignSplitData, {...campaignSplitData[campaignSplitData.length - 1]}]);
      } else {
        setCampaignSplitData([...campaignSplitData, {offer_id: null, weight: 100}]);
      }
    };

    return (
      <Box>
        {campaignSplitData.map((item, index) => (
          <Grid container rowSpacing={2} columnSpacing={1} key={index}>
            <Grid item xs={9}>
              <Autocomplete
                sx={{width: '100%', marginTop: 2}}
                disableClearable
                options={offers}
                getOptionLabel={(option) => option.name + ' (#' + option.id + ')'}
                onChange={(event, value) => {
                  set_data(index, 'offer_id', value.id);
                }}
                renderInput={(params) => <TextField {...params} label="Choose offer name"/>}
                value={offers.find(offer => offer.id === campaignSplitData[index]['offer_id'])}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField required label="Weight" variant="outlined" sx={{width: '100%', marginTop: 2}}
                         onChange={(event) => set_data(index, 'weight', event.target.value)}
                         value={item.weight}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" sx={{width: '100%', marginTop: 3}}
                          disabled={campaignSplitData.length <= 1}>
                <DeleteIcon onClick={() => delete_row(index)}/>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Box justifyContent="flex-end" mt="15px">
          <Button color="success" variant="contained" size="small" onClick={add_row}
                  disabled={campaignSplitData.length >= 10}>
            Add event
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="outlined" onClick={runModalWindow}>Edit</Button>
      </Box>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Edit campaign" subtitle={campaignTitle}/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h5' mt='10px'>
                Base settings
              </Typography>
              {base_context()}
            </Grid>

            <Grid item xs={6}>
              <Typography variant='h5' mt='10px'>
                Split settings
              </Typography>
              {split_context()}
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalEditCampaign;
