import React, {useState} from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import {requestConfig} from "../utils";
import {toast} from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../components/Header";

const ModalNewDomain = ({get_domains}) => {
  const [open, setOpen] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [registrator, setRegistrator] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [adSystem, setAdSystem] = useState("");
  const [domainChecked, setDomainChecked] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [domainSuffix, setDomainSuffix] = useState(".fun");
  const [payLoading, setPayLoading] = useState(false);
  const [adsAccounts, setAdsAccounts] = useState([]);
  const [sedoAccounts, setSedoAccounts] = useState([]);
  const [selectedAdsAccount, setSelectedAdsAccount] = useState("");
  const [selectedSedoAccount, setSelectedSedoAccount] = useState("");

  // Options for autocomplete registrators
  const registratorOptions = [
    {label: 'Ukraine.com.ua', value: 'Ukraine.com.ua'},
  ];

  // Options for autocomplete System options
  const adSystemOptions = [
    {label: 'Ads', value: 'ADS'},
    {label: 'Sedo', value: 'SEDO'},
  ];

  // Predefined domain suffixes
  const domainSuffixOptions = [
    {label: '.fun', value: '.fun'},
    {label: '.space', value: '.space'},
    {label: '.uno', value: '.uno'},
    {label: '.site', value: '.site'},
    {label: '.website', value: '.website'},
  ];

  // Fetch users and accounts from API on modal open
  const fetchUsersAndAccounts = () => {
    setLoading(true);

    // Fetch users
    const usersPromise = axios.get(`${process.env.REACT_APP_API_URL}/api/user/user_list/`, requestConfig);

    // Fetch ADS accounts
    const adsAccountsPromise = axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/accounts/`, requestConfig);

    // Fetch Sedo accounts
    const sedoAccountsPromise = axios.get(`${process.env.REACT_APP_API_URL}/api/sedo/accounts/`, requestConfig);

    Promise.all([usersPromise, adsAccountsPromise, sedoAccountsPromise])
      .then(([usersResponse, adsAccountsResponse, sedoAccountsResponse]) => {
        // Set users options
        const users = usersResponse.data.map(user => ({
          label: user.display_name,
          value: user.id
        }));
        setUserOptions(users);

        // Set ADS accounts
        setAdsAccounts(adsAccountsResponse.data.map(account => ({
          label: account,
          value: account,
        })));

        // Set Sedo accounts
        setSedoAccounts(sedoAccountsResponse.data.map(account => ({
          label: account,
          value: account,
        })));

        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching data.");
        setLoading(false);
      });
  };

  const handleCheck = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/domains/domains/check/`, {
        domain_name: `${domainName}${domainSuffix}`,
        registrator,
      }, requestConfig)
      .then((response) => {
        setLoading(false);
        setInvoiceId(response.data.invoice_id);
        setDomainChecked(true);
        toast.success("Invoice generated successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.error || "Error generating invoice.");
      });
  };

  const handleSubmit = () => {
    if (!invoiceId) {
      toast.error("You must check the domain before proceeding.");
      return;
    }

    setPayLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/domains/domains/`, {
        domain_name: `${domainName}${domainSuffix}`,
        registrator,
        ad_system: adSystem,
        invoice_id: invoiceId,
        user_id: userId,
        ads_account: adSystem === "ADS" ? selectedAdsAccount : null,
        sedo_account: adSystem === "SEDO" ? selectedSedoAccount : null,
      }, requestConfig)
      .then((response) => {
        toast.success("Domain created successfully!");
        if (adSystem === "ADS") {
          axios.post(`${process.env.REACT_APP_API_URL}/api/ppads/domains/`, {
            domain_name: `${domainName}${domainSuffix}`,
            account: selectedAdsAccount,
            user_id: userId
          }, requestConfig)
            .then((adsResponse) => {
              toast.success("Domain successfully created in ADS!");
            })
            .catch((adsError) => {
              toast.error("Error creating domain in ADS: " + (adsError.response?.data?.error || adsError.message));
            });
        } else if (adSystem === "SEDO") {
          axios.post(`${process.env.REACT_APP_API_URL}/api/sedo/domains/`, {
            domain_name: `${domainName}${domainSuffix}`,
            account: selectedSedoAccount,
            user_id: userId
          }, requestConfig)
            .then((sedoResponse) => {
              toast.success("Domain successfully created in Sedo!");
            })
            .catch((sedoError) => {
              toast.error("Error creating domain in Sedo: " + (sedoError.response?.data?.error || sedoError.message));
            });
        }
        get_domains();
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response?.data?.error || "Error creating domain.");
      })
      .finally(() => {
        setPayLoading(false);
      });
  };

  const handleOpen = () => {
    setOpen(true);
    fetchUsersAndAccounts();
  };

  const handleClose = () => {
    // Reset all states
    setOpen(false);
    setDomainName("");
    setRegistrator("");
    setInvoiceId(null);
    setAdSystem("");
    setDomainChecked(false);
    setLoading(false);
    setUserId(null);
    setDomainSuffix(".fun");
    setPayLoading(false);
    setSelectedAdsAccount("");
    setSelectedSedoAccount("");
    setAdsAccounts([]);
    setSedoAccounts([]);
  };

  const isFormValid = domainName && domainSuffix;

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={handleOpen}>Register New Domain</Button>
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        disableEscapeKeyDown={true}
      >
        <Box sx={getModalCommonStyle()}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Register New Domain" subtitle="register new domain in accounts"/>

            <IconButton onClick={handleClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Grid container spacing={2} sx={{marginTop: 3}}>
            <Grid item xs={8}>
              <TextField
                label="Domain Name"
                fullWidth
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={domainSuffixOptions}
                getOptionLabel={(option) => option.label}
                value={domainSuffixOptions.find(option => option.value === domainSuffix)}
                onChange={(event, newValue) => {
                  setDomainSuffix(newValue ? newValue.value : "");
                }}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Domain Suffix" required/>}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={registratorOptions}
                getOptionLabel={(option) => option.label}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Registrator"/>}
                onChange={(event, newValue) => {
                  setRegistrator(newValue ? newValue.value : "");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCheck}
                  disabled={!isFormValid || !registrator || loading}
                  sx={{padding: '7px', minWidth: 0}}
                >
                  {loading ? <CircularProgress size={24}/> : "Check Domain & Generate Invoice"}
                </Button>
                {domainChecked && (
                  <Box sx={{display: 'flex', alignItems: 'center', marginLeft: 2}}>
                    <CheckCircleIcon color="success"/>
                    {invoiceId && (
                      <Typography sx={{marginLeft: 1, color: 'success.main'}}>
                        {invoiceId}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            {invoiceId && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    options={adSystemOptions}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Ad System"/>}
                    onChange={(event, newValue) => {
                      setAdSystem(newValue ? newValue.value : "");
                    }}
                  />
                </Grid>
                {adSystem === "ADS" && (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={adsAccounts}
                      getOptionLabel={(option) => option.label}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="ADS Account"/>}
                      onChange={(event, newValue) => {
                        setSelectedAdsAccount(newValue ? newValue.value : "");
                      }}
                    />
                  </Grid>
                )}
                {adSystem === "SEDO" && (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={sedoAccounts}
                      getOptionLabel={(option) => option.label}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Sedo Account"/>}
                      onChange={(event, newValue) => {
                        setSelectedSedoAccount(newValue ? newValue.value : "");
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Autocomplete
                    options={userOptions}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Assign to User"/>}
                    onChange={(event, newValue) => {
                      setUserId(newValue ? newValue.value : null);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!isFormValid || !invoiceId || payLoading}
                    sx={{padding: '7px', minWidth: 0}}
                  >
                    {payLoading ? <CircularProgress size={24}/> : "Create & Pay"}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: 2}}>
            <Button onClick={handleClose} sx={{marginRight: 2}}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const getModalCommonStyle = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '12px',
});

export default ModalNewDomain;
