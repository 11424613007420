import {useState} from 'react';

const storageActiveTabHistoryPrefix = 'activeTab_'

function useActiveTabHistory(defaultTab, storageKey) {
  const savedTab = sessionStorage.getItem(`${storageActiveTabHistoryPrefix}${storageKey}`);
  const [activeTab, setActiveTab] = useState(savedTab || defaultTab);

  const setterActiveTabHistory = (newTab) => {
    setActiveTab(newTab);
    sessionStorage.setItem(`${storageActiveTabHistoryPrefix}${storageKey}`, newTab);
  };

  return [activeTab, setterActiveTabHistory];
}

const activeTabHistoryClear = (storageKey) => {
  if (storageKey) {
    sessionStorage.removeItem(`${storageActiveTabHistoryPrefix}${storageKey}`);
  }
};

const activeTabHistoryClearAll = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key.startsWith(storageActiveTabHistoryPrefix)) {
      sessionStorage.removeItem(key);
    }
  }
};


export {useActiveTabHistory, activeTabHistoryClear, activeTabHistoryClearAll};
