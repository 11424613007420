import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast } from "react-toastify";
import { requestConfig } from '../utils';

const FacebookCallback = ({ isAuthenticated }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [requestCount, setRequestCount] = useState(0);
    const [successCount, setSuccessCount] = useState(0);
    const [errorMessages, setErrorMessages] = useState([]);

    const sendCode = () => {
        let [facebookUserAccountId, facebookAccessTokenId] = searchParams.get('state').split(",");
        let authCode = searchParams.get('code');

        let url;
        let data;
        if (facebookAccessTokenId === undefined) {
            url = `${process.env.REACT_APP_API_URL}/api/facebook/user/`;
            data = { auth_code: authCode };
        } else if (facebookAccessTokenId === 'null') {
            url = `${process.env.REACT_APP_API_URL}/api/facebook/token/`;
            data = { facebook_user_account_id: facebookUserAccountId, auth_code: authCode };
        } else {
            url = `${process.env.REACT_APP_API_URL}/api/facebook/token/${facebookAccessTokenId}/refresh/`;
            data = { facebook_user_account_id: facebookUserAccountId, auth_code: authCode };
        }

        if (authCode) {
            axios.post(url, data, requestConfig)
                .then(function (response) {
                    setRequestCount(prevCount => prevCount + 1);
                    if (response.status === 200) {
                        setSuccessCount(prevCount => prevCount + 1);
                    } else {
                        setErrorMessages(prevMessages => [...prevMessages, response.data.message || '']);
                    }
                })
                .catch(function (error) {
                    setRequestCount(prevCount => prevCount + 1);
                    setErrorMessages(prevMessages => [...prevMessages, error.response?.data?.message || '']);
                });
        }
    };

    useEffect(() => {
        sendCode();
    }, [searchParams]);

    useEffect(() => {
        if (requestCount === 2) {
            if (successCount > 0) {
                toast.success("Connection is successful. Please, wait for redirect ...");
                setTimeout(() => {
                    navigate('/facebook/adaccounts/');
                }, 3000); // Redirect after 3 seconds
            } else {
                const uniqueMessages = [...new Set(errorMessages)].filter(msg => msg !== '');
                const errorMessage = uniqueMessages.length === 1 ? uniqueMessages[0] : 'An unexpected error occurred. Please try again.';
                toast.error(errorMessage);
                setTimeout(() => {
                    navigate('/facebook/user/tokens');
                }, 3000); // Redirect after 3 seconds
            }
        }
    }, [requestCount, successCount, errorMessages, navigate]);

    return (
        <div style={{ padding: '0 16px' }}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <span style={{ marginLeft: '10px' }}>Connection is successful. Please, wait for redirect ...</span>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(FacebookCallback);
