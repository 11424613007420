import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import Alert from "react-bootstrap/Alert";
import RefreshIcon from "@mui/icons-material/Refresh";
import {searchClearText, SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";


const FacebookAdAccounts = ({isAuthenticated, user}) => {
  const navigate = useNavigate();
  const searchStorage = FacebookAdAccounts.name
  const [load, setLoad] = useState(false)

  const [campaigns, setCampaigns] = useState([])

  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })
  const [pinnedRows, setPinnedRows] = useState({
    bottom: [{id: 'total', visits: "", clicks: "", revenue: "0,00", spend: "0,00", profit: "0,00",}]
  })
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const [noAccounts, setNoAccounts] = useState(false);
  const [inactiveAccounts, setInactiveAccounts] = useState(0);
  const [accountsWithoutToken, setAccountsWithoutToken] = useState(0);

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_campaigns()
  }, [paginationModel, sortModel]);


  useEffect(() => {
    searchClearText('FacebookCampaigns');
  }, []);

  const convertKeysToCamelCase = (obj) => {
    const snakeToCamel = (str) => {
      return str.replace(/(_\w)/g, match => match[1].toUpperCase());
    };

    const convertKey = (key) => {
      if (key === 'facebook_access_token') {
        return 'facebookAccessTokenId';
      }
      return snakeToCamel(key);
    };

    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = convertKey(key);
        acc[newKey] = convertKeysToCamelCase(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/user/data/`, requestConfig)
      .then(response => {
        const responseData = convertKeysToCamelCase(response.data);
        const inactiveAccounts = responseData.inactiveAccounts;
        const accountsWithoutToken = responseData.accountsWithoutToken;
        setInactiveAccounts(inactiveAccounts);
        setAccountsWithoutToken(accountsWithoutToken);
      })
      .catch(error => {
        console.error('Error fetching accounts without tokens:', error);
      });
  }, []);

  const checkTokenStatus = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/user/`, requestConfig)
      .then(function (response) {
        if (response.data.length > 0) {
          setNoAccounts(false);
        } else {
          setNoAccounts(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setNoAccounts(false); // Set condition to false in case of an error
      });
  }

  useEffect(() => {
    checkTokenStatus();
  }, []);

  const get_campaigns = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/statistic_by_ad_account/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count, totals} = response.data;
          setCampaigns(results);
          setRowCount(count)
          setPinnedRows({
            bottom: [{
              id: 'total',
              visits: "",
              clicks: "",
              revenue: totals.revenue,
              spend: totals.spend,
              profit: totals.profit,
            }]
          })
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const getRowId = (row) => {
    if (row.id === 'total') {
      return 'total'; // A unique ID for the total row
    }
    return row.name_ad; // Assuming domain_name is unique for each regular row
  };

  const columns = [
    {
      field: "name_ad",
      headerName: "Ad Account Name",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/facebook/adaccount/${params.row.id_in_db}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "conversion",
      headerName: "Conversion",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "cpa",
      headerName: "CPA",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Facebook" subtitle="statistic by advertisers"/>
      {noAccounts &&
        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: "20px"}}>
          <span>There are no connected Facebook Accounts</span>

          <Button
            variant="contained"
            href={`https://www.facebook.com/v20.0/dialog/oauth?response_type=code%20granted_scopes&client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/facebook/callback_refresh/&scope=ads_read&state=clickid`}
            sx={{marginLeft: '20px'}}
          >
            Connect Facebook
          </Button>
        </Box>
        ||
        <Link to={`/facebook/user/tokens`} style={{marginBottom: '20px'}}>Show all connected FB accounts</Link>
      }

      <Box>
        {inactiveAccounts > 0 && (
          <Alert variant="danger" style={{width: "42rem", fontSize: "10px", padding: "8px", marginBottom: "20px"}}>
            <Alert.Heading style={{fontSize: "14px"}}>
              There are {inactiveAccounts} broken tokens.
            </Alert.Heading>
          </Alert>
        )}
        {accountsWithoutToken > 0 && (
          <Alert variant="warning" style={{width: "42rem", fontSize: "10px", padding: "8px", marginBottom: "20px"}}>
            <Alert.Heading style={{fontSize: "14px"}}>
              There are {accountsWithoutToken} accounts without token.
            </Alert.Heading>
          </Alert>
        )}
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          getRowId={getRowId}
          rowCount={rowCount}
          pinnedRows={pinnedRows}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          hideFooterSelectedRowCount
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(FacebookAdAccounts);
