import * as React from 'react';
import {LineChart} from '@mui/x-charts/LineChart';


const MultiLineChart = (props) => {
  return (
    <LineChart
      xAxis={[
        {
          id: 'Day',
          data: props.data.date.length !== 0 ? props.data.date : [new Date('2023-07-05'), new Date('2023-07-06'),],
          scaleType: 'time',
          valueFormatter: (date) => date.toDateString(),
        },
      ]}
      series={[
        {
          id: 'Spend',
          label: 'Spend',
          data: props.data.spend.length !== 0 ? props.data.spend : [0, 0],
          // stack: 'total',
          area: true,
        },
        {
          id: 'Revenue',
          label: 'Revenue',
          data: props.data.revenue.length !== 0 ? props.data.revenue : [0, 0],
          // stack: 'total',
          area: true,
        },
        {
          id: 'Net',
          label: 'Net',
          data: props.data.net.length !== 0 ? props.data.net : [0, 0],
          // stack: 'total',
          area: true,
        }

      ]}
      // xAxis={[
      //     {
      //     id: 'Years',
      //     data: years,
      //     scaleType: 'time',
      //     valueFormatter: (date) => date.getFullYear(),
      //     },
      // ]}
      // series={[
      //     {
      //     id: 'France',
      //     label: 'French GDP per capita',
      //     data: FranceGDPperCapita,
      //     stack: 'total',
      //     area: true,
      //     },
      //     {
      //     id: 'Germany',
      //     label: 'German GDP per capita',
      //     data: GermanyGDPperCapita,
      //     stack: 'total',
      //     area: true,
      //     },
      //     {
      //     id: 'United Kingdom',
      //     label: 'UK GDP per capita',
      //     data: UKGDPperCapita,
      //     stack: 'total',
      //     area: true,
      //     },
      // ]}
      sx={{
        '--ChartsLegend-itemWidth': '200px',
      }}
      width={1000}
      height={400}
      margin={{left: 70}}
    />
  );
}

export default MultiLineChart;