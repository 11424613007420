import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import React from "react";

const Header = ({title, subtitle}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="5px" mt="17px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{m: "0 0 5px 0"}}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
      <hr color={colors.grey[100]}/>
    </Box>
  );
};

export default Header;
