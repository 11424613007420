const initialState = {
  selectedEmployee: localStorage.getItem('masqueradeUser') || null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SELECTED_EMPLOYEE':
      return {
        ...state,
        selectedEmployee: action.payload,
      };
    default:
      return state;
  }
};
