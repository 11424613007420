import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRangePicker} from 'react-date-range';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {Button} from "@mui/material";

export const Calender = () => {
  const dispatchDate = useDispatch();
  const startDateR = useSelector(state => state.date.startDate);
  const endDateR = useSelector(state => state.date.endDate);
  const startDateStr = useSelector(state => state.date.startDateStr);
  const endDateStr = useSelector(state => state.date.endDateStr);

  const [tempStartDate, setTempStartDate] = useState(startDateR);
  const [tempEndDate, setTempEndDate] = useState(endDateR);

  const handleSelect = (date) => {
    setTempStartDate(date.selection.startDate);
    setTempEndDate(date.selection.endDate);
  }

  const selectionRange = {
    startDate: tempStartDate,
    endDate: tempEndDate,
    key: 'selection',
  }

  const handleOpen = () => {
    setTempStartDate(startDateR);
    setTempEndDate(endDateR);
  };

  const applyFilter = () => {
    dispatchDate({type: 'SET_START', payload: tempStartDate});
    dispatchDate({type: 'SET_END', payload: tempEndDate});

    // Save date to localStorage
    localStorage.setItem('startDate', tempStartDate.toISOString());
    localStorage.setItem('endDate', tempEndDate.toISOString());

    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  const popupRef = React.createRef(); // Create a ref for Popup component

  return (
    <Popup
      ref={popupRef}
      trigger={
        <button className="btn btn-primary md">{startDateStr} &nbsp;~&nbsp; {endDateStr}</button>
      } modal nested closeOnEscape
      contentStyle={{width: 'auto', padding: '16px'}}
      overlayStyle={{zIndex: 9999}}
      onOpen={handleOpen}
    >
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
        format='yyyy-MM-dd'
        weekStartsOn={1}
      />
      <Button
        style={{
          width: '250px',
          marginTop: '12px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        onClick={applyFilter}
        variant="contained"
      >
        Apply
      </Button>
    </Popup>
  )
}
