import {useEffect, useState} from "react";
import axios from "axios";
import {Box, Button, Grid, IconButton, Modal, TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";
import DeleteIcon from "@mui/icons-material/Delete";

const ModalNewCampaign = (props) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [domains, setDomains] = useState([]);
  const [conversions, setConversions] = useState([]);

  const [campaignWorkspaceId, setCampaignWorkspaceId] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [campaignDomainId, setCampaignDomainId] = useState('');
  const [offerAdText, setOfferAdText] = useState('');
  const [offerUrl, setOfferUrl] = useState('');
  const [offerKeywords, setOfferKeywords] = useState('');
  const [offerTemplate, setOfferTemplate] = useState('');
  const [sourcePixel, setSourcePixel] = useState('');
  const [sourceToken, setSourceToken] = useState('');
  const [sourceEventsData, setSourceEventsData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const default_event = {conversion: 'conversion', event: 'Purchase'};

  const styleModal = {
    ...getModalCommonStyle(),
    width: '60%',
  };

  const get_offer_data = async (offer_id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/${offer_id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          let link_offer;
          try {
            const currentUrl = new URL(response.data.url);
            link_offer = currentUrl.origin + '/';
          } catch (error) {
            link_offer = response.data.url;
          }

          setOfferAdText(response.data.ad_text);
          setOfferUrl(link_offer);
          setOfferKeywords(response.data.keywords);
          setOfferTemplate(response.data.template);
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const load_references = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/workspaces/`, requestConfig)
      .then(function (response) {
        setWorkspaces(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/domains/`, requestConfig)
      .then(function (response) {
        setDomains(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/conversions/`, requestConfig)
      .then(function (response) {
        setConversions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.data !== undefined && props.data !== null) {
      runModalWindow();
    } else {
      setShowModal(false);
    }
  }, [props.data]);

  const runModalWindow = async () => {
    await load_references();

    if (props.data.campaigns_offers.length > 0) {
      await get_offer_data(props.data.campaigns_offers[0].offer_id);
    } else {
      setOfferAdText('');
      setOfferUrl('');
      setOfferKeywords('');
      setOfferTemplate(null);
    }

    setCampaignWorkspaceId(props.data.workspace.id);
    setCampaignName(props.data.name);
    if (props.data.traffic_source.integrations.length > 0) {
      setSourcePixel(props.data.traffic_source.integrations[0].integration_setting.pixel_id);
      setSourceToken('');
      if (props.data.traffic_source.integrations[0].conversions_events.length > 0) {
        let event_data = [];
        setSourceEventsData([]);
        props.data.traffic_source.integrations[0].conversions_events.forEach((element) => {
          event_data = [...event_data, {
            conversion: element.conversion.index,
            event: element.event,
          }];
        });
        setSourceEventsData(event_data);
      } else {
        setSourceEventsData([default_event]);
      }
    } else {
      setSourcePixel('');
      setSourceToken('');
      setSourceEventsData([default_event]);
    }
    setCampaignDomainId(props.data.domain.id);

    setShowModal(true);
  };

  const onModalClose = () => {
    props.setData(null);
  };

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const onSubmit = async () => {
    const data = {
      workspace_id: campaignWorkspaceId,
      name: campaignName,
      ad_text: offerAdText,
      url: offerUrl,
      pixel: sourcePixel,
      token: sourceToken,
      keywords: offerKeywords,
      template: offerTemplate,
      domain_id: campaignDomainId,
      conversions_events: sourceEventsData,
    };

    setButtonCreateDisabled(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/clickflare/campaigns/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            toast.success('Success! Tracker link will be created in 5-20 seconds', {
              position: "top-center",
              autoClose: 3000,
            });
            props.setData(null);
          } else {
            toast.error(response.data);
          }
          props.get_campaigns();
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        });
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  const base_context = () => {
    return (
      <Box>
        <Autocomplete
          sx={{width: '90%', marginTop: 2}}
          disableClearable
          options={workspaces}
          getOptionLabel={(option) => option.name}
          onChange={(event, values) => setCampaignWorkspaceId(values.id)}
          renderInput={(params) => <TextField {...params} label="Choose workspace"/>}
          value={workspaces.find(workspace => workspace.id === campaignWorkspaceId)}
        />

        <TextField
          required
          label="Title"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setCampaignName(event.target.value)}
          value={campaignName}
        />

        <TextField
          required
          label="Ad text"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setOfferAdText(event.target.value)}
          value={offerAdText}
        />

        <TextField
          required
          label="Offer's url"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setOfferUrl(event.target.value)}
          value={offerUrl}
        />

        <TextField
          label="Pixel ID"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setSourcePixel(event.target.value)}
          value={sourcePixel}
        />

        <TextField
          label="Token"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setSourceToken(event.target.value)}
          value={sourceToken}
        />

        <TextField
          label="Keywords separated by comma"
          variant="outlined"
          sx={{width: '90%', marginTop: 2}}
          onChange={(event) => setOfferKeywords(event.target.value)}
          value={offerKeywords}
        />

        <Autocomplete
          sx={{width: '90%', marginTop: 2}}
          disableClearable
          options={props.offer_templates}
          getOptionLabel={(option) => option.value}
          onChange={(event, values) => {
            setOfferTemplate(values.id);
          }}
          renderInput={(params) => <TextField {...params} label="Choose template offer link"/>}
          value={props.offer_templates.find(template => template.id === offerTemplate)}
        />

        <Autocomplete
          sx={{width: '90%', marginTop: 2}}
          disableClearable
          options={domains}
          getOptionLabel={(option) => option.domain}
          onChange={(event, values) => {
            setCampaignDomainId(values.id);
          }}
          renderInput={(params) => <TextField {...params} label="Choose domain"/>}
          value={domains.find(domain => domain.id === campaignDomainId)}
        />
      </Box>
    );
  };

  const event_context = () => {
    const set_data = (index, name, value) => {
      let prevState = [...sourceEventsData];
      prevState[index][name] = value;
      setSourceEventsData(prevState);
    };

    const delete_row = (index) => {
      let prevState = [...sourceEventsData];
      prevState.splice(index, 1);
      setSourceEventsData(prevState);
    };

    const add_row = () => {
      if (sourceEventsData.length > 0) {
        setSourceEventsData([...sourceEventsData, {...sourceEventsData[sourceEventsData.length - 1]}]);
      } else {
        setSourceEventsData([...sourceEventsData, default_event]);
      }
    };

    return (
      <Box>
        {sourceEventsData.map((item, index) => (
          <Grid container rowSpacing={2} columnSpacing={1} key={index}>
            <Grid item xs={5.5}>
              <Autocomplete
                sx={{width: '100%', marginTop: 2}}
                disableClearable
                options={conversions}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  set_data(index, 'conversion', value.index);
                }}
                renderInput={(params) => <TextField {...params} label="Choose conversion name"/>}
                value={conversions.find(conversion => conversion.index === sourceEventsData[index].conversion)}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Autocomplete
                sx={{width: '100%', marginTop: 2}}
                disableClearable
                options={props.source_events}
                onChange={(event, value) => {
                  set_data(index, 'event', value);
                }}
                renderInput={(params) => <TextField {...params} label="Choose event name"/>}
                value={props.source_events.find(event => event === sourceEventsData[index].event)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" sx={{width: '100%', marginTop: 3}}
                          disabled={sourceEventsData.length <= 1}>
                <DeleteIcon onClick={() => delete_row(index)}/>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Box justifyContent="flex-end" mt="15px">
          <Button color="success" variant="contained" size="small" onClick={add_row}
                  disabled={sourceEventsData.length >= 3}>
            Add event
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={() => {
          props.setData({
            'workspace': {id: null},
            'name': '',
            'traffic_source': {'integrations': []},
            'domain': {id: null},
            'campaigns_offers': [],
          });
        }}>Create new tracker link</Button>
      </Box>

      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Create link" subtitle="create new tracker link"/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h5' mt='10px'>
                Base settings
              </Typography>
              {base_context()}
            </Grid>

            <Grid item xs={6}>
              <Typography variant='h5' mt='10px'>
                Event settings
              </Typography>
              {event_context()}
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Create New Tracker Link
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  );
};

export default ModalNewCampaign;
