import axios from "axios";
import {Box, Button} from "@mui/material";
import {toast} from "react-toastify";

import {formatErrorMessage, requestConfig} from "../utils";
import {useState} from "react";


const InvokePixel = (props) => {
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const send_request = async () => {
    setButtonCreateDisabled(true);
    await axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/${props.id}/pixel/invoke/`, requestConfig)
      .then(function (response) {
        console.log(response.data)
        toast.success('Success!', {position: "top-center", autoClose: 3000});
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
      .finally(function () {
        setButtonCreateDisabled(false);
      });
  }

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="text" onClick={send_request} disabled={isButtonCreateDisabled}>
          Activate
        </Button>
      </Box>

    </Box>
  )
}

export default InvokePixel;
