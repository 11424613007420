const countries_google = [
  {code: "us", name: "United States"},
  {code: "uk", name: "United Kingdom"},
  {code: "ca", name: "Canada"},
  {code: "ru", name: "Russia"},
  {code: "de", name: "Germany"},
  {code: "fr", name: "France"},
  {code: "es", name: "Spain"},
  {code: "it", name: "Italy"},
  {code: "br", name: "Brazil"},
  {code: "au", name: "Australia"},
  {code: "ar", name: "Argentina"},
  {code: "be", name: "Belgium"},
  {code: "ch", name: "Switzerland"},
  {code: "dk", name: "Denmark"},
  {code: "fi", name: "Finland"},
  {code: "hk", name: "Hong Kong"},
  {code: "ie", name: "Ireland"},
  {code: "il", name: "Israel"},
  {code: "mx", name: "Mexico"},
  {code: "nl", name: "Netherlands"},
  {code: "no", name: "Norway"},
  {code: "pl", name: "Poland"},
  {code: "se", name: "Sweden"},
  {code: "sg", name: "Singapore"},
  {code: "tr", name: "Turkey"},
  {code: "jp", name: "Japan"},
  {code: "in", name: "India"},
  {code: "hu", name: "Hungary"},
  {code: "af", name: "Afghanistan"},
  {code: "al", name: "Albania"},
  {code: "dz", name: "Algeria"},
  {code: "ao", name: "Angola"},
  {code: "am", name: "Armenia"},
  {code: "at", name: "Austria"},
  {code: "az", name: "Azerbaijan"},
  {code: "bh", name: "Bahrain"},
  {code: "bd", name: "Bangladesh"},
  {code: "by", name: "Belarus"},
  {code: "bz", name: "Belize"},
  {code: "bo", name: "Bolivia"},
  {code: "ba", name: "Bosnia and Herzegovina"},
  {code: "bw", name: "Botswana"},
  {code: "bn", name: "Brunei"},
  {code: "bg", name: "Bulgaria"},
  {code: "cv", name: "Cabo Verde"},
  {code: "kh", name: "Cambodia"},
  {code: "cm", name: "Cameroon"},
  {code: "cl", name: "Chile"},
  {code: "co", name: "Colombia"},
  {code: "cr", name: "Costa Rica"},
  {code: "hr", name: "Croatia"},
  {code: "cy", name: "Cyprus"},
  {code: "cz", name: "Czech Republic"},
  {code: "cd", name: "Congo"},
  {code: "do", name: "Dominican Republic"},
  {code: "ec", name: "Ecuador"},
  {code: "eg", name: "Egypt"},
  {code: "sv", name: "El Salvador"},
  {code: "ee", name: "Estonia"},
  {code: "et", name: "Ethiopia"},
  {code: "ge", name: "Georgia"},
  {code: "gh", name: "Ghana"},
  {code: "gr", name: "Greece"},
  {code: "gt", name: "Guatemala"},
  {code: "gy", name: "Guyana"},
  {code: "ht", name: "Haiti"},
  {code: "hn", name: "Honduras"},
  {code: "is", name: "Iceland"},
  {code: "id", name: "Indonesia"},
  {code: "jm", name: "Jamaica"},
  {code: "jo", name: "Jordan"},
  {code: "kz", name: "Kazakhstan"},
  {code: "kw", name: "Kuwait"},
  {code: "lv", name: "Latvia"},
  {code: "lb", name: "Lebanon"},
  {code: "lt", name: "Lithuania"},
  {code: "lu", name: "Luxembourg"},
  {code: "mg", name: "Madagascar"},
  {code: "my", name: "Malaysia"},
  {code: "mt", name: "Malta"},
  {code: "mu", name: "Mauritius"},
  {code: "md", name: "Moldova"},
  {code: "mn", name: "Mongolia"},
  {code: "me", name: "Montenegro"},
  {code: "ma", name: "Morocco"},
  {code: "mz", name: "Mozambique"},
  {code: "na", name: "Namibia"},
  {code: "np", name: "Nepal"},
  {code: "nz", name: "New Zealand"},
  {code: "ni", name: "Nicaragua"},
  {code: "ng", name: "Nigeria"},
  {code: "om", name: "Oman"},
  {code: "py", name: "Paraguay"},
  {code: "pe", name: "Peru"},
  {code: "ph", name: "Philippines"},
  {code: "pt", name: "Portugal"},
  {code: "ro", name: "Romania"},
  {code: "sa", name: "Saudi Arabia"},
  {code: "sn", name: "Senegal"},
  {code: "rs", name: "Serbia"},
  {code: "sk", name: "Slovakia"},
  {code: "si", name: "Slovenia"},
  {code: "za", name: "South Africa"},
  {code: "kr", name: "South Korea"},
  {code: "lk", name: "Sri Lanka"},
  {code: "th", name: "Thailand"},
  {code: "bs", name: "Bahamas"},
  {code: "tt", name: "Trinidad and Tobago"},
  {code: "tn", name: "Tunisia"},
  {code: "ua", name: "Ukraine"},
  {code: "ae", name: "United Arab Emirates"},
  {code: "uy", name: "Uruguay"},
  {code: "ve", name: "Venezuela"},
  {code: "vn", name: "Vietnam"},
  {code: "zm", name: "Zambia"},
  {code: "zw", name: "Zimbabwe"},
  {code: "ly", name: "Libya"},
];

const countries_taboola = [
  "Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla",
  "Antarctica", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan",
  "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan",
  "Bolivia", "Bonaire Saint Eustatius and Saba", "Bosnia and Herzegovina", "Botswana", "Brazil",
  "British Indian Ocean Territory", "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia",
  "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China",
  "Christmas Island", "Colombia", "Comoros", "Congo", "Congo The Democratic Republic of the", "Cook Islands",
  "Costa Rica", "Cote d’Ivoire", "Croatia", "Cuba", "Curacao", "Cyprus", "Czech Republic", "Denmark", "Djibouti",
  "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia",
  "Ethiopia", "Europe", "Falkland Islands (Malvinas)", "Faroe Islands", "Fiji", "Finland", "France", "French Guiana",
  "French Polynesia", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada",
  "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Holy See (Vatican City State)",
  "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia", "Iran Islamic Republic of", "Iraq", "Ireland",
  "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kiribati",
  "Korea Democratic People’s Republic of", "Korea Republic of", "Kuwait", "Kyrgyzstan", "Lao People’s Democratic Republic",
  "Latvia", "Lebanon", "Lesotho", "Liberia", "Libyan Arab Jamahiriya", "Liechtenstein", "Lithuania", "Luxembourg", "Macao",
  "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania",
  "Mauritius", "Mayotte", "Mexico", "Micronesia Federated States of", "Moldova Republic of", "Monaco", "Mongolia", "Montenegro",
  "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Caledonia", "New Zealand",
  "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau",
  "Palestinian Territory", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn", "Poland", "Portugal",
  "Puerto Rico", "Qatar", "Reunion", "Romania", "Russian Federation", "Rwanda", "Saint Bartelemey", "Saint Kitts and Nevis",
  "Saint Lucia", "Saint Martin", "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
  "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten",
  "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname",
  "Swaziland", "Sweden", "Switzerland", "Syrian Arab Republic", "Taiwan", "Tajikistan", "Tanzania United Republic of", "Thailand",
  "Timor-Leste", "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands",
  "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands",
  "Unknown", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Virgin Islands British", "Virgin Islands U.S.",
  "Wallis and Futuna", "Yemen", "Zambia", "Zimbabwe"
];

export {
  countries_google,
  countries_taboola,
};
