import React, {useEffect, useState} from "react";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {toast} from "react-toastify";
import {tokens} from "../theme";
import Header from "./Header";
import {getModalCommonStyle, requestConfig} from "../containers/utils";


const AutoRules = (props) => {
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const [rules, setRules] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsAll, setCampaignsAll] = useState([]);
  const [checkedCampaigns, setCheckedCampaigns] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [searchEdit, setSearchEdit] = useState('');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let listElement = null;

  const defaultRules = [
    {rule_type: 'TODAY', action: 'BLOCK', spend_limit: '', cpa_limit: ''},
    {rule_type: 'TODAY', action: 'ACTIVATE', spend_limit: '', cpa_limit: ''},
    {rule_type: 'LAST_WEEK', action: 'BLOCK', spend_limit: '', cpa_limit: ''},
    {rule_type: 'LAST_WEEK', action: 'ACTIVATE', spend_limit: '', cpa_limit: ''},
  ];

  const styleModal = {
    ...getModalCommonStyle(),
    width: '70%',
  };

  const getRuleData = (rule_type, action, property) => {
    const rule = rules.find(rule => rule.rule_type === rule_type && rule.action === action);
    if (rule && rule.hasOwnProperty(property)) {
      return rule[property];
    } else {
      return '';
    }
  };

  const setRuleData = (rule_type, action, updates) => {
    const updatedRules = rules.map(rule => {
      if (rule.rule_type === rule_type && rule.action === action) {
        return {...rule, ...updates};
      }
      return rule;
    });
    setRules(updatedRules);
  };

  useEffect(() => {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

    setCampaignsAll(props.myCampaigns.filter(
      campaign => campaign.id !== props.campaign_id && (campaign.created === null || new Date(campaign.created) < twoDaysAgo))
    );
    setCampaigns(campaignsAll)

    if (!openCreate) {
      setCheckedCampaigns([])
      setSearchEdit('')
      setCheckAll(false);
    }
  }, [openCreate]);

  const load_campaign_auto_rules = () => {
    axios.get(`${process.env.REACT_APP_API_URL}${props.endpoint}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const fetchedRules = defaultRules.map(defaultRule => {
            const matchingRule = response.data.find(rule =>
              rule.rule_type === defaultRule.rule_type && rule.action === defaultRule.action
            );
            return matchingRule ? {...defaultRule, ...matchingRule} : defaultRule;
          });
          setRules(fetchedRules);
        } else {
          console.log(response.data);
          toast.error(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText)
      })
  };

  const onClose = () => {
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleClick = () => {
    load_campaign_auto_rules();
    setOpenCreate(true);
  }

  const onClear = () => {
    setRules(prevRules => prevRules.map(rule => ({...rule, spend_limit: '', cpa_limit: ''})));
  };

  const onSubmit = async () => {
    const payload = {
      campaign_list: [...checkedCampaigns, props.campaign_id],
      auto_rules: rules
    }

    setButtonCreateDisabled(true);
    try {
      axios.post(`${process.env.REACT_APP_API_URL}${props.endpoint}`, payload, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            setOpenCreate(false);
            props.get_campaigns();
          } else {
            toast.error(response.statusText)
          }
        })
        .catch(function (error) {
          toast.error(error.message)
        })
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  const handleToggle = (id) => () => {
    const currentIndex = checkedCampaigns.indexOf(id);
    const newChecked = [...checkedCampaigns];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedCampaigns(newChecked);
  };

  const handleToggleAll = () => {
    const filteredCampaignIds = campaigns
      .filter(campaign => campaign.campaign_name.toLowerCase().includes(searchEdit.toLowerCase()))
      .map(campaign => campaign.id);

    if (checkAll) {
      setCheckedCampaigns(prevChecked =>
        prevChecked.filter(id => !filteredCampaignIds.includes(id))
      );
    } else {
      setCheckedCampaigns(prevChecked =>
        Array.from(new Set([...prevChecked, ...filteredCampaignIds]))
      );
    }
    setCheckAll(!checkAll);
  };

  const handleSearch = (text) => {
    const searchText = text.toLowerCase();
    const firstCampaigns = campaignsAll.filter(campaign =>
      campaign.campaign_name.toLowerCase().includes(searchText)
    );
    const lastCampaigns = campaignsAll.filter(campaign =>
      !campaign.campaign_name.toLowerCase().includes(searchText)
    );
    setCampaigns([...firstCampaigns, ...lastCampaigns]);

    setSearchEdit(text);
    setCheckAll(false)

    if (listElement) {
      listElement.scrollTop = 0;
    }
  };

  const CustomListItem = ({item, checked, handleToggle}) => {
    // Function to highlight specific substring with a background color
    const highlightSubstring = (text, substring, backgroundColor) => {
      const parts = text.split(new RegExp(`(${substring})`, 'gi'));
      return (
        <Box component="span" key={text}>
          {parts.map((part, index) =>
            part.toLowerCase() === substring.toLowerCase() ? (
              <Box
                component="span"
                key={index}
                color={"HighlightText"}
                sx={{backgroundColor, borderRadius: 0}}
              >
                {part}
              </Box>
            ) : (
              <Box component="span" key={index}>
                {part}
              </Box>
            )
          )}
        </Box>
      );
    };

    return (
      <ListItem key={item.id} sx={{padding: "0 10px"}}>
        <Checkbox checked={checked} onChange={handleToggle(item.id)}/>
        <Typography variant="body1">
          {highlightSubstring(item.campaign_name, searchEdit, "Highlight")}
        </Typography>
      </ListItem>
    );
  };

  return (
    <Box>
      <Box sx={{display: 'flex'}}>
        {/* Button */}
        <Button variant="outlined" onClick={handleClick}>
          {props.with_auto_rules ? 'Edit' : '+'}
        </Button>

        {/* Modal */}
        <Modal
          open={openCreate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          backdrop="static"
          onKeyDown={handleKeyDown}
        >
          <Box sx={styleModal}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 2}}>
              {/* Header */}
              <Header title="Automated rules" subtitle={`${props.campaignTitle || ''}`}/>

              {/* Close Button */}
              <IconButton onClick={onClose} sx={{position: 'absolute', top: 15, right: 15}}>
                <CloseIcon/>
              </IconButton>
            </Box>

            {/* Main Content */}
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1,}}>
              {/* Left Side - Rules */}
              <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1,}}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  {/* Today */}
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{marginBottom: '10px', color: colors.blueAccent[500]}}>
                      Today
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Box display="flex" justifyContent="right" alignItems="center" height="100%">
                          <div>BLOCK</div>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Spend limit"
                          variant="outlined"
                          sx={{width: '100%'}}
                          value={getRuleData('TODAY', 'BLOCK', 'spend_limit')}
                          onChange={(event) => setRuleData('TODAY', 'BLOCK', {spend_limit: event.target.value})}
                          type="number"
                          InputProps={{
                            inputProps: {
                              inputMode: 'decimal',
                              step: 'any',
                              min: '0',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="CPA limit"
                          variant="outlined"
                          sx={{width: '100%'}}
                          value={getRuleData('TODAY', 'BLOCK', 'cpa_limit')}
                          onChange={(event) => setRuleData('TODAY', 'BLOCK', {cpa_limit: event.target.value})}
                          type="number"
                          inputProps={{
                            inputMode: 'decimal',
                            step: 'any',
                            min: '0',
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{marginTop: '-5px'}}>
                      <Grid item xs={2}>
                        <Box display="flex" justifyContent="right" alignItems="center" height="100%">
                          <div>ACTIVATE</div>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Spend limit"
                          variant="outlined"
                          sx={{width: '100%', visibility: 'hidden'}}
                          value={getRuleData('TODAY', 'ACTIVATE', 'spend_limit')}
                          onChange={(event) => setRuleData('TODAY', 'ACTIVATE', {spend_limit: event.target.value})}
                          type="number"
                          inputProps={{
                            inputMode: 'decimal',
                            step: 'any',
                            min: '0',
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="CPA limit"
                          variant="outlined"
                          sx={{width: '100%'}}
                          value={getRuleData('TODAY', 'ACTIVATE', 'cpa_limit')}
                          onChange={(event) => setRuleData('TODAY', 'ACTIVATE', {cpa_limit: event.target.value})}
                          type="number"
                          inputProps={{
                            inputMode: 'decimal',
                            step: 'any',
                            min: '0',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Last Week */}
                  <Grid item xs={12} sx={{marginTop: '20px'}}>
                    <Typography variant="h5" sx={{marginBottom: '10px', color: colors.blueAccent[500]}}>
                      Last Week
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Box display="flex" justifyContent="right" alignItems="center" height="100%">
                          <div>BLOCK</div>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Spend limit"
                          variant="outlined"
                          sx={{width: '100%'}}
                          value={getRuleData('LAST_WEEK', 'BLOCK', 'spend_limit')}
                          onChange={(event) => setRuleData('LAST_WEEK', 'BLOCK', {spend_limit: event.target.value})}
                          type="number"
                          InputProps={{
                            inputProps: {
                              inputMode: 'decimal',
                              step: 'any',
                              min: '0',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="CPA limit"
                          variant="outlined"
                          sx={{width: '100%'}}
                          value={getRuleData('LAST_WEEK', 'BLOCK', 'cpa_limit')}
                          onChange={(event) => setRuleData('LAST_WEEK', 'BLOCK', {cpa_limit: event.target.value})}
                          type="number"
                          inputProps={{
                            inputMode: 'decimal',
                            step: 'any',
                            min: '0',
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{marginTop: '-5px'}}>
                      <Grid item xs={2}>
                        <Box display="flex" justifyContent="right" alignItems="center" height="100%">
                          <div>ACTIVATE</div>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Spend limit"
                          variant="outlined"
                          sx={{width: '100%', visibility: 'hidden'}}
                          value={getRuleData('LAST_WEEK', 'ACTIVATE', 'spend_limit')}
                          onChange={(event) => setRuleData('LAST_WEEK', 'ACTIVATE', {spend_limit: event.target.value})}
                          type="number"
                          inputProps={{
                            inputMode: 'decimal',
                            step: 'any',
                            min: '0',
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="CPA limit"
                          variant="outlined"
                          sx={{width: '100%'}}
                          value={getRuleData('LAST_WEEK', 'ACTIVATE', 'cpa_limit')}
                          onChange={(event) => setRuleData('LAST_WEEK', 'ACTIVATE', {cpa_limit: event.target.value})}
                          type="number"
                          inputProps={{
                            inputMode: 'decimal',
                            step: 'any',
                            min: '0',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Footer Buttons */}
                <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '85px'}}>
                  <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
                    Save changes
                  </Button>

                  <Button color="secondary" variant="text" onClick={onClear} disabled={isButtonCreateDisabled}>
                    Clear
                  </Button>
                </Box>
              </Box>

              {/* Right Side - List Box */}
              <Box
                sx={{
                  width: '50%',
                  borderLeft: '1px solid #ccc',
                  padding: '0 0 0 20px',
                  flexShrink: 0,
                  marginLeft: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                {/* Title */}
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                  <Typography variant="h5" sx={{color: colors.blueAccent[500], flexGrow: 1, textAlign: 'center'}}>
                    Also apply to:
                  </Typography>
                  <Typography variant="h6" sx={{marginLeft: 'auto'}}>
                    [ {checkedCampaigns.length} of {campaigns.length} ]
                  </Typography>
                </Box>

                {/* List */}
                <Box
                  sx={{
                    flex: 1,
                    maxHeight: 350,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    marginTop: '10px',
                  }}
                  ref={(el) => {
                    listElement = el;
                  }}
                >
                  <List sx={{width: '100%'}}>
                    {campaigns.map((item) => (
                      <CustomListItem
                        key={item.id}
                        item={item}
                        checked={checkedCampaigns.indexOf(item.id) !== -1}
                        handleToggle={() => handleToggle(item.id)}
                      />
                    ))}
                  </List>
                </Box>

                {/* Bottom */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}>
                  <Checkbox sx={{color: colors.blueAccent[500], marginLeft: "9px"}}
                            checked={checkAll}
                            onChange={handleToggleAll}
                  />
                  <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                    <TextField
                      placeholder="Search"
                      variant="standard"
                      value={searchEdit}
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      size="small"
                      sx={{width: '90%'}}
                    />
                  </Box>
                </Box>

              </Box>
            </Box>

          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default AutoRules;
