import {useEffect, useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalNewOffer = (props) => {
  const [campaigns, setCampaigns] = useState([]);

  const [offerCampaignId, setOfferCampaignId] = useState("");
  const [offerName, setOfferName] = useState("");
  const [offerAdText, setOfferAdText] = useState('');
  const [offerUrl, setOfferUrl] = useState('');
  const [offerKeywords, setOfferKeywords] = useState('');
  const [offerTemplate, setOfferTemplate] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const styleModal = {
    ...getModalCommonStyle(),
    width: 470,
  };

  const load_references = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/campaigns/reference/`, requestConfig)
      .then(function (response) {
        setCampaigns(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.data !== undefined && props.data !== null) {
      runModalWindow();
    } else {
      setShowModal(false);
    }
  }, [props.data]);

  const runModalWindow = async () => {
    await load_references();

    let link_offer;
    try {
      const currentUrl = new URL(props.data.url);
      link_offer = currentUrl.origin + '/';
    } catch (error) {
      link_offer = props.data.url;
    }

    setOfferCampaignId(props.data.campaign_id || '');
    setOfferName(props.data.name || '');
    setOfferAdText(props.data.ad_text || '');
    setOfferUrl(link_offer || '');
    setOfferKeywords(props.data.keywords || '');
    setOfferTemplate(props.data.template || '');

    setShowModal(true);
  };
  
  const onModalClose = () => {
    props.setData(null);
  };

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const onSubmit = async () => {
    const data = {
      campaign_id: offerCampaignId,
      name: offerName,
      ad_text: offerAdText,
      url: offerUrl,
      keywords: offerKeywords,
      template: offerTemplate,
    };

    setButtonCreateDisabled(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            toast.success('Success! Offer will be created in 5-20 seconds', {
              position: "top-center",
              autoClose: 3000,
            });
            props.setData(null);
          } else {
            toast.error(response.data);
          }
          props.get_campaigns();
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        });
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={() => {
          props.setData({
            'campaign_id': {id: null},
            'name': '',
            'url': '',
            'keywords': '',
            'template': null,
          });
        }}>Create new Offer</Button>
      </Box>

      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Create offer" subtitle="create new offer"/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Autocomplete
            sx={{width: 400, marginTop: 2}}
            disableClearable
            options={campaigns}
            getOptionLabel={(option) => option.name}
            onChange={(event, values) => {
              setOfferCampaignId(values.id);
              if (offerName === '') {
                setOfferName(values.name);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Choose campaign"/>}
            value={campaigns.find(campaign => campaign.id === offerCampaignId)}
          />

          <TextField
            required
            label="Title"
            variant="outlined"
            sx={{width: 400, marginTop: 2}}
            onChange={(event) => setOfferName(event.target.value)}
            value={offerName}
          />

          <TextField
            required
            label="Ad text"
            variant="outlined"
            sx={{width: 400, marginTop: 2}}
            onChange={(event) => setOfferAdText(event.target.value)}
            value={offerAdText}
          />

          <TextField
            required
            label="Offer's url"
            variant="outlined"
            sx={{width: 400, marginTop: 2}}
            onChange={(event) => setOfferUrl(event.target.value)}
            value={offerUrl}
          />

          <TextField
            label="Keywords separated by comma"
            variant="outlined"
            sx={{width: 400, marginTop: 2}}
            onChange={(event) => setOfferKeywords(event.target.value)}
            value={offerKeywords}
          />

          <Autocomplete
            sx={{width: 400, marginTop: 2}}
            disableClearable
            options={props.offer_templates}
            getOptionLabel={(option) => option.value}
            onChange={(event, values) => setOfferTemplate(values.id)}
            renderInput={(params) => <TextField {...params} label="Choose template offer link"/>}
            value={props.offer_templates.find(template => template.id === offerTemplate)}
          />

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Create New Offer
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  );
};

export default ModalNewOffer;
