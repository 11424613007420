import {format} from 'date-fns';

const savedStartDate = localStorage.getItem('startDate');
const savedEndDate = localStorage.getItem('endDate');

const defaultStartDate = savedStartDate ? new Date(savedStartDate) : new Date();
const defaultEndDate = savedEndDate ? new Date(savedEndDate) : new Date();

const defaultDate = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  startDateStr: format(defaultStartDate, 'yyyy-MM-dd'),
  endDateStr: format(defaultEndDate, 'yyyy-MM-dd'),
};

export default function (state = defaultDate, action) {
  switch (action.type) {
    case 'SET_START':
      return {
        ...state,
        startDate: action.payload,
        startDateStr: format(action.payload, 'yyyy-MM-dd')
      }
    case 'SET_END':
      return {
        ...state,
        endDate: action.payload,
        endDateStr: format(action.payload, 'yyyy-MM-dd')
      }

    default:
      return state;
  }
}
