import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";


const ModalOffer = (props) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const [requestData, setRequestData] = useState(props.offer_data)
  const setData = (name, value) => {
    setRequestData(prevState => ({
      ...prevState, [name]: value
    }))
  }

  const styleModal = {
    ...getModalCommonStyle(),
    width: 700,
  };

  const onClose = () => {
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const onSubmit = async () => {
    setButtonCreateDisabled(true);
    try {
      axios.put(`${process.env.REACT_APP_API_URL}/api/redtrack/offers/${props.offer_data.id}/`, requestData, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            setOpenCreate(false);
            toast.success('Offer successfully updated!', {
              position: "top-center",
              autoClose: 3000
            });
            props.get_campaigns();
          } else {
            toast.error(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        })
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="outlined" onClick={() => setOpenCreate(true)}>Edit</Button>
      </Box>
      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Edit offer" subtitle={props.offer_data.title}/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required id="outlined-basic" label="Edit title" variant="outlined" sx={{width: 630, marginTop: 3}}
                     defaultValue={props.offer_data.title}
                     onChange={(event) => setData('title', event.target.value)}/>

          <TextField required id="outlined-basic" label="Keywords" variant="outlined" sx={{width: 630, marginTop: 3}}
                     defaultValue={props.offer_data.keywords}
                     onChange={(event) => setData('keywords', event.target.value)}/>

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Save changes
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  )
}

export default ModalOffer;
