import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";

import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalEditOffer = (props) => {
  const [offerTitle, setOfferTitle] = useState('');
  const [offerName, setOfferName] = useState('');
  const [offerKeywords, setOfferKeywords] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const styleModal = {
    ...getModalCommonStyle(),
    width: 470,
  };

  const get_offer_data = async (offer_id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/${offer_id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setOfferTitle(response.data.name);
          setOfferName(response.data.name);
          setOfferKeywords(response.data.keywords);
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.message);
      });
  };

  const runModalWindow = async () => {
    await get_offer_data(props.offer_id);
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const onSubmit = async () => {
    const data = {
      name: offerName,
      keywords: offerKeywords,
    };

    setButtonCreateDisabled(true);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/${props.offer_id}/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setShowModal(false);
            toast.success('Success!', {position: "top-center", autoClose: 3000});
            props.get_campaigns();
          } else {
            toast.error(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        });
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="outlined" onClick={runModalWindow}>Edit</Button>
      </Box>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Edit offer" subtitle={offerTitle}/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required label="Title" variant="outlined" sx={{width: 400, marginTop: 2}}
                     onChange={(event) => setOfferName(event.target.value)} value={offerName}/>

          <TextField required label="Keywords separated by comma" variant="outlined" sx={{width: 400, marginTop: 2}}
                     onChange={(event) => setOfferKeywords(event.target.value)}
                     value={offerKeywords}/>

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalEditOffer;
