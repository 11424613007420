import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {toast} from "react-toastify";


const OutBrainPublishers = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;
  const navigate = useNavigate();
  const searchStorage = OutBrainPublishers.name
  const [load, setLoad] = useState(false)

  const [titleCampaign, setTitleCampaign] = useState("");
  const [publishers, setPublishers] = useState([])

  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_campaigns()
  }, [startStr, endStr, selectedEmployee, searchText]);


  const get_campaigns = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/outbrain/campaigns/${cid}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setTitleCampaign(response.data.campaign_name)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/outbrain/statistic_by_publisher/?campaign=${cid}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setPublishers(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const handleBlockedClick = (params) => {
    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/outbrain/statistic_by_publisher/switch_block/?campaign=${cid}&publisher=${params.row.publisher_id}`, requestConfig)
      .then(function (response) {
        if (response.status === 202) {
          params.row.blocked = response.data.blocked;
        }
      })
      .catch(function (error) {
        toast.error(error.message)
      })
      .finally(function () {
        setLoad(false);
      })
  };

  const columns = [
    {
      field: "blocked",
      align: 'center',
      headerAlign: 'center',
      headerName: "Blocked",
      flex: 0.01,
      aggregable: false,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <div style={{cursor: 'pointer'}} onClick={() => handleBlockedClick(params)}>
            {params.row.blocked === true ? '⛔️' : params.rowNode.type === 'leaf' ? '◯' : null}
          </div>
        );
      }
    },
    {
      field: "publisher_name",
      headerName: "Publisher Name",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "conversion",
      headerName: "Conversion",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "cpa",
      headerName: "CPA",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Campaign ${titleCampaign || ""}`} subtitle="statistic by publishers"/>

      <Box m="0 0 20px 0">
        <Link to={`/`} onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '0 0 15px', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={publishers}
          columns={columns}
          getRowId={(row) => row.publisher_id}
          initialState={{
            aggregation: {
              model: {
                spend: 'sum',
                revenue: 'sum',
                profit: 'sum',
              },
            },
          }}
          density="compact"
          hideFooterSelectedRowCount
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(OutBrainPublishers);
