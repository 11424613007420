import React, {useState} from "react";
import {Link, Navigate} from "react-router-dom";
import {connect} from 'react-redux';
import {login} from "../actions/auth";
import {useMode} from "../theme";

const Login = ({login, isAuthenticated}) => {
  const [theme, colorMode] = useMode();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const {email, password} = formData;

  const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});
  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Navigate to='/facebook/adaccounts'/>
  }

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-6">
          <hr style={{background: "white"}}/>
          <h2 className="text-center" style={{color: theme.palette.text.primary}}>AD MEDIA PROFIT QUALITY OÜ</h2>
          <hr style={{background: "white"}}/>
          <h1 className="text-center" style={{color: theme.palette.text.primary}}>Sign In</h1>
          <p className="text-center" style={{color: theme.palette.text.primary}}>Sign into your Account</p>
          <form onSubmit={e => onSubmit(e)}>
            <div className="form-group">
              <input className="form-control" type="email" name="email" placeholder="Email" value={email}
                     onChange={e => onChange(e)} required/>
            </div>
            <div className="form-group">
              <input className="form-control" type="password" name="password" placeholder="Password"
                     value={password}
                     minLength='6' onChange={e => onChange(e)} required/>
            </div>
            <button className="btn btn-primary col-md-12" type='submit'>Login</button>
          </form>
          <p className="text-center mt-3">Don't have an account? <Link to='/signup'>Sign Up</Link></p>
          <p className="text-center">Forgot your Password? <Link to='/reset_password'>Reset Password</Link></p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  // console.log("Current isAuthenticated state:", state.auth.isAuthenticated);
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, {login})(Login);
