import axios from 'axios';
import store from '../store';
import {logout, refreshToken} from './auth';

axios.interceptors.response.use(
  response => response,  // Return the response directly if no errors
  async error => {
    const originalRequest = error.config;
    const exemptedUrls = [
      `${process.env.REACT_APP_API_URL}/auth/jwt/create/`,
      `${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`,
      `${process.env.REACT_APP_API_URL}/auth/jwt/verify/`,
    ];

    // Check if error response is 401 and not one of the exempted URLs
    if (
      error.response &&
      error.response.status === 401 &&
      !exemptedUrls.includes(originalRequest.url)
    ) {
      try {
        // Attempt to refresh token if it's a 401 error
        const refreshResponse = await store.dispatch(refreshToken());

        if (refreshResponse) {
          // Update the Authorization header with the new access token
          originalRequest.headers['Authorization'] = `JWT ${localStorage.getItem('access')}`

          // Retry the original request
          return axios(originalRequest);
        } else {
          store.dispatch(logout());
        }
      } catch (refreshError) {
        // If token refresh fails, log out the user
        store.dispatch(logout());
      }
    }

    // If we can't refresh the token or it's another type of error, reject the promise
    return Promise.reject(error);
  }
);
