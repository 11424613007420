import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";


const RSOC2Statistic = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false)

  const [channels, setChannels] = useState([])

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_channels()
  }, [startStr, endStr, selectedEmployee]);


  const get_channels = () => {
    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/rsoc2/statistic/?start_date=${startStr}&end_date=${endStr}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setChannels(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      })
  }

  const columns = [
    {
      field: "campaign",
      headerName: "Campaign",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "rpc",
      headerName: "RPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="RSOC2 Statistic" subtitle="statistic by campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_channels()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={channels}
          columns={columns}
          // getRowId={(row) => row.campaign}
          initialState={{
            aggregation: {
              model: {
                revenue: 'sum',
                ad_clicks: 'sum',
                impressions: 'sum',
              },
            },
          }}
          density="compact"
          hideFooterSelectedRowCount={true}
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(RSOC2Statistic);
