import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";
import Header from "../../components/Header";
import {getModalCommonStyle, requestConfig} from "../utils";

const ModalSource = (props) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const [pixel, setPixel] = useState(props.pixel)
  const [token, setToken] = useState(props.token)
  const [event, setEvent] = useState(props.event_name)

  const styleModal = {
    ...getModalCommonStyle(),
    width: 700,
  };

  const onClose = () => {
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const onSubmit = async () => {
    const data = {
      title: props.source_name,
      id: props.source_id,
      pixel: pixel,
      token: token,
      event: event
    }

    setButtonCreateDisabled(true);
    try {
      axios.put(`${process.env.REACT_APP_API_URL}/api/redtrack/sources/${props.source_id}/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            setOpenCreate(false);
            toast.success('Source successfully updated!', {
              position: "top-center",
              autoClose: 3000
            });
            props.get_campaigns()
          } else {
            toast.error(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(error.response.statusText)
        })
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  const choose_event = ['Purchase', 'ViewContent', 'Lead', 'Search']

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="outlined" onClick={() => setOpenCreate(true)}>Edit</Button>
      </Box>
      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Edit source" subtitle={props.source_name}/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required id="outlined-basic" label="Pixel" variant="outlined" sx={{width: 630, marginTop: 3}}
                     defaultValue={pixel}
                     onChange={(event) => setPixel(event.target.value)}/>

          <TextField required id="outlined-basic" label="Token" variant="outlined" sx={{width: 630, marginTop: 3}}
                     defaultValue={token}
                     onChange={(event) => setToken(event.target.value)}/>

          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={choose_event}
            defaultValue={event}
            getOptionLabel={(option) => option}
            sx={{width: 300, marginTop: 3}}
            onChange={(event, values) => setEvent(values)}
            renderInput={(params) => <TextField {...params} label="Choose event name"/>}
          />

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ModalSource;
