import React, {useEffect, useState} from "react";
import axios from "axios";
import {connect, useSelector} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {activeTabHistoryClear} from "../../hocs/ActiveTabHistory";

const TonicCampaignsStatistic = ({isAuthenticated, user}) => {
  const navigate = useNavigate();
  const searchStorage = TonicCampaignsStatistic.name
  const [load, setLoad] = useState(false)
  const [campaigns, setCampaigns] = useState([]);
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })
  const [pinnedRows, setPinnedRows] = useState({
    bottom: [{
      id: 'total',
      visits: "0",
      clicks: "0",
      revenue: "0,00",
      spend: "0,00",
      profit: "0,00",
      roi: "0.00",
      epc: "0.000",
    }]
  })
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    activeTabHistoryClear('TonicOneCampaignStatistic');
  }, []);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_campaigns()
  }, [paginationModel, sortModel]);


  const get_campaigns = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/statistic/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count, totals} = response.data;
          setCampaigns(results);
          setRowCount(count)
          setPinnedRows({
            bottom: [{
              id: 'total',
              visits: totals.visits,
              clicks: totals.clicks,
              revenue: totals.revenue,
              spend: totals.spend,
              profit: totals.profit,
              roi: totals.roi,
              epc: totals.epc,
            }]
          })
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      })
  };

  const getRowId = (row) => {
    if (row.id === 'total') {
      return 'total'; // A unique ID for the total row
    }
    return row.campaign_name; // Assuming domain_name is unique for each regular row
  };

  const columns = [
    {
      field: "account",
      headerName: "Account",
      flex: 0.1,
      cellClassName: "name-column--cell",
    },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.3,
      renderCell: (params) => {
        const displayStatus = (params.row.status === null || params.row.status === false) ? '⛔️' : '';
        const displayText = params.value || '';
        return (
          <Link to={`/tonic/statistic/${params.row.campaign_id}`}>{`${displayStatus} ${displayText}`}</Link>
        )
      }
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Tonic" subtitle="statistic by campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          inputProps={{sx: {height: '36px', padding: '0 14px'}}}
          sx={{height: '36px', maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          getRowId={getRowId}
          rowCount={rowCount}
          pinnedRows={pinnedRows}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(TonicCampaignsStatistic);
