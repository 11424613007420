import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {activeTabHistoryClear} from "../../hocs/ActiveTabHistory";

const CodeFuelCampaignsStatistic = ({isAuthenticated, account, user}) => {
  const navigate = useNavigate();
  const searchStorage = CodeFuelCampaignsStatistic.name + account
  const [load, setLoad] = useState(false)

  const [campaigns, setCampaigns] = useState([])

  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })
  const [pinnedRows, setPinnedRows] = useState({
    bottom: [{id: 'total', visits: "0", clicks: "0", revenue: "0,00", spend: "0,00", profit: "0,00", epc: "0.000",}]
  })
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    activeTabHistoryClear('CodeFuelOneCampaignStatistic');
  }, []);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_campaigns()
  }, [paginationModel, sortModel]);


  const get_campaigns = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/codefuel/statistic/?account=${account}&page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count, totals} = response.data;
          setCampaigns(results)
          setRowCount(count)
          setPinnedRows({
            bottom: [{
              id: 'total',
              visits: totals.visits,
              clicks: totals.clicks,
              revenue: totals.revenue,
              spend: totals.spend,
              profit: totals.profit,
              roi: totals.roi,
              epc: totals.epc,
            }]
          })
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      })
  }

  const getRowId = (row) => {
    if (row.id === 'total') {
      return 'total'; // A unique ID for the total row
    }
    return row.campaign_id; // Assuming domain_name is unique for each regular row
  };

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/codefuel/statistic/${account}/${params.row.campaign_id}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "count_days",
      headerName: "3+ days",
      align: 'center',
      headerAlign: 'center',
      flex: 0.05,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <span style={{color: 'tomato'}}>{params.value > 3 ? "✔" : ""}</span>
        )
      }
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={account.charAt(0).toUpperCase() + account.slice(1).toLowerCase() + ' 2'}
              subtitle="statistic by campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          inputProps={{sx: {height: '36px', padding: '0 14px'}}}
          sx={{height: '36px', maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          getRowId={getRowId}
          rowCount={rowCount}
          pinnedRows={pinnedRows}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    account: props.account || "UNDEF",
    user: state.auth.user
  }
};

export default connect(mapStateToProps)(CodeFuelCampaignsStatistic);
