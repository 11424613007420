import React, {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../components/Header";
import Autocomplete from "@mui/material/Autocomplete";

import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalPixel = (props) => {
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [sources, setSources] = useState(['facebook'])
  const [eventNames, setEventNames] = useState([])

  const mapEventNames = {
    "tiktok": ['Complete Payment'],
    "facebook": ['Purchase', 'ViewContent', 'Conversion', 'Lead'],
    "taboola": ['lead'],
  }

  const defaultPixel = {
    source: "facebook",
    event_name: "empty",
    send_revenue: true,
    revenue_type: 'preestimated_revenue',
    pixel_id: "",
    access_token: "",
    events_s2s_conversion: true,
    domain_verification: "",
    deduplication_parameter: "random_id",
  }

  const [pixelData, setPixelData] = useState(defaultPixel)

  const setData = (name, value) => {
    setPixelData(prevState => ({
      ...prevState, [name]: value
    }))
    console.log(pixelData)
  }

  const get_pixel_data = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/${props.id}/pixel/`, requestConfig)
      .then(function (response) {
        const pixel_data = response.data
        setSources([pixel_data.source])
        setEventNames(mapEventNames[pixel_data.source])
        setPixelData({...pixel_data})
        setOpenCreate(true);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
  }

  const openModal = async () => {
    await get_pixel_data();
  }

  const closeModal = () => {
    setOpenCreate(false)
    setPixelData(defaultPixel)
    setSources([])
    setEventNames([])
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const onSubmit = async () => {
    setButtonCreateDisabled(true);
    await axios.post(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/${props.id}/pixel/`, pixelData, requestConfig)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Success!', {position: "top-center", autoClose: 3000});
          closeModal()
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
      .finally(function () {
        setButtonCreateDisabled(false);
      });
  }

  const styleModal = {
    ...getModalCommonStyle(),
    width: 670,
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="text" onClick={openModal}>pixel</Button>
      </Box>
      <Modal
        open={openCreate}
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title='Pixel' subtitle={props.campaign_name}/>

            <IconButton onClick={closeModal} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>

          </Box>

          <Autocomplete
            sx={{width: 300, marginTop: 3}}
            hidden={!['facebook', 'tiktok', 'taboola'].includes(pixelData.source)}
            disabled
            required
            options={sources}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.source}
            onChange={(event, values) => setData('source', values)}
            renderInput={(params) => <TextField {...params} label="Source"/>}
          />

          <Autocomplete
            sx={{width: 300, marginTop: 3}}
            hidden={!['facebook', 'tiktok', 'taboola'].includes(pixelData.source)}
            required
            options={eventNames}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.event_name}
            onChange={(event, values) => setData('event_name', values)}
            renderInput={(params) => <TextField {...params} label="Choose Event"/>}
          />

          <Autocomplete
            sx={{width: 300, marginTop: 3}}
            hidden={!['facebook', 'tiktok', 'taboola'].includes(pixelData.source)}
            required
            disableClearable
            options={['YES', 'NO']}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.send_revenue ? 'YES' : 'NO'}
            onChange={(event, values) => setData('send_revenue', values === 'YES')}
            renderInput={(params) => <TextField {...params} label="Send Revenue?"/>}
          />

          <TextField
            sx={{width: 600, marginTop: 3}}
            hidden={!['facebook', 'tiktok', 'taboola'].includes(pixelData.source)}
            required
            label="Pixel"
            variant="outlined"
            defaultValue={pixelData.pixel_id}
            onChange={(event) => setData('pixel_id', event.target.value)}
          />

          <TextField
            sx={{width: 600, marginTop: 3}}
            hidden={!['facebook', 'tiktok', 'taboola'].includes(pixelData.source)}
            required
            label="Token"
            variant="outlined"
            defaultValue={pixelData.access_token}
            onChange={(event) => setData('access_token', event.target.value)}
          />

          <TextField
            sx={{width: 600, marginTop: 3}}
            hidden={!['facebook', 'taboola'].includes(pixelData.source)}
            label="Domain Verification (optional)"
            variant="outlined"
            defaultValue={pixelData.domain_verification}
            onChange={(event) => setData('domain_verification', event.target.value)}
          />

          <TextField
            sx={{width: 600, marginTop: 3}}
            hidden={!['facebook', 'taboola'].includes(pixelData.source)}
            disabled
            label="Deduplication Parameter (optional)"
            variant="outlined"
            defaultValue={pixelData.deduplication_parameter}
            onChange={(event) => setData('deduplication_parameter', event.target.value)}
          />

          {pixelData.send_revenue && (
            <TextField
              sx={{width: 600, marginTop: 3}}
              hidden={!['tiktok'].includes(pixelData.source)}
              disabled
              label="Revenue type (optional)"
              variant="outlined"
              defaultValue={pixelData.revenue_type}
              onChange={(event) => setData('revenue_type', event.target.value)}
            />)
          }

          <Autocomplete
            sx={{width: 600, marginTop: 3}}
            hidden={!['tiktok'].includes(pixelData.source)}
            required
            disabled
            disablePortal
            options={['YES', 'NO']}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.events_s2s_conversion ? 'YES' : 'NO'}
            onChange={(event, values) => setData('events_s2s_conversion', values === 'YES')}
            renderInput={(params) => <TextField {...params} label="Use Tonic tracking by default?"/>}
          />

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Set Pixel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ModalPixel;
