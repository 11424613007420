import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {connect, useSelector} from 'react-redux';
import {Box, Button} from "@mui/material";
import {toast} from "react-toastify";
import Header from "../../components/Header";
import CrossNewCampaign from "./ModalNewCampaign";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {convertSortModelToDjangoSort, getDataGridCommonStyle, ModalCircularProgress, requestConfig} from "../utils";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {activeTabHistoryClear} from "../../hocs/ActiveTabHistory";


const CrossCampaigns = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const searchStorage = CrossCampaigns.name
  const [load, setLoad] = useState(false)

  const [campaigns, setCampaigns] = useState([]);

  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    activeTabHistoryClear('CrossOneCampaignStatistic');
  }, []);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [searchText, selectedEmployee]);

  useEffect(() => {
    get_campaigns();
  }, [paginationModel, sortModel]);


  const get_campaigns = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true);
    axios.get(`${process.env.REACT_APP_API_URL}/api/cross/campaigns/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count} = response.data;
          setCampaigns(results);
          setRowCount(count)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      })
  }

  const handleOnCellClick = (text) => {
    // navigator.clipboard.writeText(params);
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
    toast.success("Link copied", {position: "top-center", autoClose: 2000});
  };

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/cross/statistic/${params.row.id}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "link",
      headerName: "Link",
      flex: 0.3,
      renderCell: (params) => (
        <div className="offerlink" onClick={() => {
          handleOnCellClick(params.value)
        }}>{params.value}</div>
      )
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="CrossRoads" subtitle="list of campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <CrossNewCampaign get_campaigns={get_campaigns}/>

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          rowCount={rowCount}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(CrossCampaigns);
