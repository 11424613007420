import React, {createContext, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {checkAuthenticated, load_user} from '../actions/auth';
import {ModalCircularProgress} from "../containers/utils";

export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);

  const checkAuthenticatedHandler = useCallback(async () => {
    await dispatch(checkAuthenticated());
  }, [dispatch]);

  const loadUserHandler = useCallback(async () => {
    await dispatch(load_user());
  }, [dispatch]);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await checkAuthenticatedHandler();
        await loadUserHandler();
      } catch (error) {
        console.error("Failed to initialize authentication", error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [checkAuthenticatedHandler, loadUserHandler]);

  if (loading) {
    return <ModalCircularProgress load={true}/>;
  }

  return (
    <AuthContext.Provider value={{user, isAuthenticated, loading}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
