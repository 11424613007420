import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {connect} from 'react-redux';
import {Box, Button} from "@mui/material";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {convertSortModelToDjangoSort, getDataGridCommonStyle, ModalCircularProgress, requestConfig} from "../utils";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {activeTabHistoryClear} from "../../hocs/ActiveTabHistory";

const YahooCodefuelChannels = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const searchStorage = YahooCodefuelChannels.name;
  const [load, setLoad] = useState(false);
  const [channels, setChannels] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => searchLoadText(searchStorage));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    activeTabHistoryClear('YahooCodefuelOneStatistic');
  }, []);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [searchText]);

  useEffect(() => {
    getChannels();
  }, [paginationModel, sortModel]);

  const getChannels = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/yahoo_codefuel/channels/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count} = response.data;
          const formattedResults = results.map((item, index) => ({
            id: index,
            ...item,
          }));
          setChannels(formattedResults || []);
          setRowCount(count || 0);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columns = [
    {
      field: "channel",
      headerName: "Channel",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const channel = params.value || "";
        return (
          <Link to={`/yahoo_codefuel/statistic/${encodeURIComponent(channel)}`}>
            {channel}
          </Link>
        );
      },
    },
  ];

  return (
    <Box sx={{display: "flex", flexDirection: "column", height: "calc(100% - 16px)"}}>
      <Header title="Yahoo" subtitle="list of channels"/>

      <Box sx={{display: "flex", alignItems: "center", margin: "-5px 0 15px 0", minHeight: "38px", width: "100%"}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value);
          }}
          sx={{maxWidth: "600px", flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: "7px", minWidth: 0}}
          variant="contained"
          onClick={getChannels}
        >
          <RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "auto",
          ...getDataGridCommonStyle(),
        }}
      >
        <DataGridPremium
          rows={channels}
          columns={columns}
          rowCount={rowCount}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(YahooCodefuelChannels);
