import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../components/Header";

import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalKeywords = (props) => {
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [keywords, setKeywords] = useState([])

  const get_keywords = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/${props.id}/keywords/`, requestConfig)
      .then(function (response) {
        const keywords = response.data.keywords;
        const emptyElementsToAdd = 10 - keywords.length;
        for (let i = 0; i < emptyElementsToAdd; i++) {
          keywords.push('');
        }

        setKeywords(keywords);
        setOpenCreate(true);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
        closeModal();
      })
  }

  const keywords_change = (index, event) => {
    const key_array = keywords;
    key_array[index] = event.target.value;
    setKeywords(key_array);
  }

  const openModal = async () => {
    await get_keywords();
  }

  const closeModal = () => {
    setOpenCreate(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const onSubmit = async () => {
    const data = {
      keywords: keywords.filter(element => element !== ""),
    }

    setButtonCreateDisabled(true);
    await axios.post(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/${props.id}/keywords/`, data, requestConfig)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Success!', {position: "top-center", autoClose: 3000});
          closeModal();
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
      .finally(function () {
        setButtonCreateDisabled(false);
      });
  }

  const styleModal = {
    ...getModalCommonStyle(),
    width: 520,
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="text" onClick={openModal}>keywords</Button>
      </Box>
      <Modal
        open={openCreate}
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Keywords" subtitle={`${props.campaign_name}`}/>

            <IconButton onClick={closeModal} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          {keywords.map((keyword, index) => (
            <Box>
              <TextField key={index} placeholder="Keyword" variant="outlined" sx={{width: 450, marginTop: 2}}
                         defaultValue={keyword} size="small"
                         onChange={(event) => keywords_change(index, event)}
              />
            </Box>

          ))}
          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={onSubmit} disabled={isButtonCreateDisabled}>
              Set keywords
            </Button>
          </Box>

        </Box>

      </Modal>
    </Box>
  )
}

export default ModalKeywords;
