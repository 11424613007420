import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import axios from 'axios';
import {Box} from '@mui/material';
import {DataGridPremium} from '@mui/x-data-grid-premium';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Header from '../../components/Header';
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig,
} from '../utils';
import {useActiveTabHistory} from '../../hocs/ActiveTabHistory';

const YahooCodefuelOneStatistic = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid; // Channel ID

  const [datesData, setDatesData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);

  const [sortModelDates, setSortModelDates] = useState([]);
  const [sortModelCountries, setSortModelCountries] = useState([]);
  const [titleChannel, setTitleChannel] = useState(cid);

  const [load, setLoad] = useState(false);
  const [activeTab, setActiveTab] = useActiveTabHistory('tab_dates', YahooCodefuelOneStatistic.name);

  const startStr = useSelector((state) => state.date.startDateStr);
  const endStr = useSelector((state) => state.date.endDateStr);
  const navigate = useNavigate();


  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getData();
  }, [activeTab, startStr, endStr]);

  const getData = () => {
    setLoad(true);

    if (activeTab === 'tab_dates') {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/yahoo_codefuel/statistic_by_date/?channel=${cid}&start_date=${startStr}&end_date=${endStr}`,
          requestConfig
        )
        .then((response) => {
          if (response.status === 200) {
            setDatesData(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoad(false);
        });
    } else if (activeTab === 'tab_countries') {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/yahoo_codefuel/statistic_by_country/?channel=${cid}&start_date=${startStr}&end_date=${endStr}`,
          requestConfig
        )
        .then((response) => {
          if (response.status === 200) {
            setCountriesData(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const columnsDates = [
    {field: 'date', headerName: 'Date', flex: 0.1},
    {field: 'channel', headerName: 'Channel', flex: 0.3},
    {field: 'visits', headerName: 'Clicks', flex: 0.1, ...column_as_number},
    {field: 'ctr', headerName: 'CTR, %', flex: 0.1, ...column_as_number_2_digits},
    {field: 'epc', headerName: 'EPC', flex: 0.1, ...column_as_number_3_digits},
    {field: 'clicks', headerName: 'Conversions', flex: 0.1, ...column_as_number},
    {field: 'revenue', headerName: 'Revenue', flex: 0.1, ...column_as_number_2_digits},
  ];


  const columnsCountries = [
    {field: 'country_code', headerName: 'Country', flex: 0.3, cellClassName: 'name-column--cell'},
    {field: 'visits', headerName: 'Clicks', flex: 0.1, ...column_as_number},
    {field: 'ctr', headerName: 'CTR, %', flex: 0.1, ...column_as_number_2_digits},
    {field: 'epc', headerName: 'EPC', flex: 0.1, ...column_as_number_3_digits},
    {field: 'clicks', headerName: 'Conversions', flex: 0.1, ...column_as_number},
    {field: 'revenue', headerName: 'Revenue', flex: 0.1, ...column_as_number_2_digits},
  ];

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }
    setActiveTab(newValue);

    if (newValue !== 'tab_dates') {
      setDatesData([]);
    }
    if (newValue !== 'tab_countries') {
      setCountriesData([]);
    }
  };

  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Channel ${titleChannel}`} subtitle="statistic by channel"/>

      <Box m="0 0 20px 0">
        <Link to={`/`} onClick={() => navigate(-1)}>
          <span style={{marginRight: '5px'}}>{'\u2190'}</span>Back
        </Link>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs value={activeTab} onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
            <Tab value="tab_dates" label="Statistic by Date"/>
            <Tab value="tab_countries" label="Statistic by Country"/>
          </Tabs>

          <TabPanel
            value="tab_dates"
            sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}
          >
            <DataGridPremium
              {...commonGridProps}
              rows={datesData}
              columns={columnsDates}
              getRowId={(row) => `${row.date}-${row.asset_gid}-${row.country_code}`}
              initialState={{
                aggregation: {
                  model: {

                    visits: 'sum',
                    clicks: 'sum',
                    epc: 'avg',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModelDates,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelDates(newModel);
              }}
            />
          </TabPanel>

          <TabPanel
            value="tab_countries"
            sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}
          >
            <DataGridPremium
              {...commonGridProps}
              rows={countriesData}
              columns={columnsCountries}
              getRowId={(row) => `${row.country_code}-${row.asset_gid}`}
              initialState={{
                aggregation: {
                  model: {
                    visits: 'sum',
                    clicks: 'sum',
                    revenue: 'sum',
                    epc: 'avg',
                  },
                },
                sorting: {
                  sortModel: sortModelCountries,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelCountries(newModel);
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(YahooCodefuelOneStatistic);
