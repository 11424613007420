import React, {useRef, useState} from "react";
import axios from "axios";
import {Autocomplete, Box, Button, TextField, useTheme} from "@mui/material";

import {tokens} from "../../theme";
import {toast} from "react-toastify";
import {DateRangePicker} from 'react-date-range';
import Popup from 'reactjs-popup';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SearchIcon from '@mui/icons-material/Search';
import {
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {countries_taboola} from "./countries";
import {DataGridPremium} from "@mui/x-data-grid-premium";

const languages = ["da", "de", "el", "en", "es", "fi", "fr", "hr", "it", "jp", "ko", "lt", "mk", "nl", "pl", "pt", "ro", "ru", "sv", "uk"];
const platforms = ["DESKTOP", "MOBILE", "TABLET"];
const searchPartners = ["GOOGLE", "YAHOO", "BING", "OTHER"];

const formatDateLocal = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const TaboolaKeywords = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const popupRef = useRef();
  const [load, setLoad] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(new Date());
  const [tempEndDate, setTempEndDate] = useState(new Date());

  const dateRange = {
    startDate: tempStartDate,
    endDate: tempEndDate,
    key: 'selection'
  }

  const [dataRequest, setDataRequest] = useState({
    start_date: formatDateLocal(tempStartDate),
    end_date: formatDateLocal(tempEndDate),
    keyword: "",
    country: "",
    category: "",
    language: "",
    platform: "",
    search_partner: ""
  });


  const setData = (name, value) => {
    setDataRequest(prevState => ({...prevState, [name]: value}));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const disableFutureDates = (date) => {
    const today = new Date();
    return date > today;
  };

  const onDateRangeChange = (ranges) => {
    setTempStartDate(ranges.selection.startDate);
    setTempEndDate(ranges.selection.endDate);
  };

  const onDateRangeApply = () => {
    setData('start_date', formatDateLocal(tempStartDate));
    setData('end_date', formatDateLocal(tempEndDate));
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  const onCountryChange = (event, newValue) => {
    setData('country', newValue || "");
  };

  const onSubmit = () => {
    setLoad(true);
    axios.post(`${process.env.REACT_APP_API_URL}/api/keys/taboola/`, dataRequest, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setKeywords(response.data.data);
          setIsDataLoaded(true);
        } else {
          console.log(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        const errorMessage = error.response && error.response.statusText ? error.response.statusText : 'An unexpected error occurred';
        toast.error(errorMessage);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columns = [
    {field: "keyword", headerName: "Keyword", flex: 0.3},
    {field: "cpc", headerName: "CPC", flex: 0.1, ...column_as_number_3_digits},
    {field: "cpa", headerName: "CPA", flex: 0.1, ...column_as_number_3_digits},
    {field: "cvr", headerName: "CVR", flex: 0.1, ...column_as_number_3_digits},
    {field: "vctr", headerName: "VCTR", flex: 0.1, ...column_as_number_3_digits},
    {field: "vcpm", headerName: "VCPM", flex: 0.1, ...column_as_number_3_digits},
    {field: "cpc_change", headerName: "CPC Change", flex: 0.1, ...column_as_number_2_digits},
    {field: "vcpm_change", headerName: "VCPM Change", flex: 0.1, ...column_as_number_2_digits},
    {field: "vctr_change", headerName: "VCTR Change", flex: 0.1, ...column_as_number_2_digits},
    {field: "cpa_change", headerName: "CPA Change", flex: 0.1, ...column_as_number_2_digits},
    {field: "cvr_change", headerName: "CVR Change", flex: 0.1, ...column_as_number_2_digits},
  ];

  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    pagination: true,
    pageSizeOptions: [100],
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', padding: 0}}>
      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', alignItems: 'center', gap: 2, marginTop: '15px'}}>
        <Popup
          ref={popupRef}
          trigger={
            <Button
              size="small"
              variant="outlined"
              sx={{
                borderColor: colors.grey[600],
                color: 'lightgray',
                height: '36px',
                fontSize: '0.9rem',
                marginLeft: '1px',
                padding: '5px 5px',
                width: '200px',
                borderRadius: '5px',
              }}
            >
              {`${dataRequest.start_date} ~ ${dataRequest.end_date}`}
            </Button>
          }
          modal
          nested
          closeOnEscape
          closeOnDocumentClick
          contentStyle={{width: 'auto', padding: '16px'}}
        >
          <DateRangePicker
            ranges={[dateRange]}
            onChange={onDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction="vertical"
            maxDate={new Date()}
            disabledDay={(date) => disableFutureDates(date)}
            format='yyyy-MM-dd'
            weekStartsOn={1}
          />
          <Button onClick={onDateRangeApply}
                  sx={{
                    width: '250px',
                    marginTop: '12px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                  variant="contained">
            Apply
          </Button>
        </Popup>

        <TextField
          sx={{width: '415px'}}
          required
          label="Keyword"
          size="small"
          variant="outlined"
          onChange={(event) => setData('keyword', event.target.value)}
          onKeyPress={handleKeyPress}
        />

        <TextField
          sx={{width: '200px'}}
          label="Campaign category"
          size="small"
          variant="outlined"
          onChange={(event) => setData('category', event.target.value)}
        />

        <Button
          color="secondary"
          variant="contained"
          onClick={onSubmit}
          startIcon={<SearchIcon/>}
        >
          Search
        </Button>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', gap: 2, marginTop: '15px'}}>
        <Autocomplete
          sx={{width: '200px'}}
          size="small"
          options={languages}
          getOptionLabel={(option) => option.toUpperCase()}
          onChange={(event, value) => setData('language', value)}
          renderInput={(params) => (
            <TextField {...params} label="Language" variant="outlined"/>
          )}
          defaultValue={'en'}
        />

        <Autocomplete
          sx={{width: '200px'}}
          size="small"
          options={countries_taboola}
          onChange={(event, value) => setData('country', value)}
          renderInput={(params) => (
            <TextField {...params} label="Country" variant="outlined"/>
          )}
          defaultValue={'United States'}
        />

        <Autocomplete
          sx={{width: '200px'}}
          size="small"
          options={platforms}
          onChange={(event, value) => setData('platform', value)}
          renderInput={(params) => (
            <TextField {...params} label="Platform" variant="outlined"/>
          )}
        />

        <Autocomplete
          sx={{width: '200px'}}
          size="small"
          options={searchPartners}
          onChange={(event, value) => setData('search_partner', value)}
          renderInput={(params) => (
            <TextField {...params} label="Search Partner" variant="outlined"/>
          )}
        />
      </Box>

      <Box sx={{
        display: 'flex', flexDirection: 'column', flex: 1, marginTop: '15px', padding: 0, overflow: 'auto'
      }}>
        {isDataLoaded && (
          <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
            <DataGridPremium
              {...commonGridProps}
              rows={keywords}
              columns={columns}
              getRowId={(row) => row.keyword}
              sx={{height: "calc(100vh - 276px)"}}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TaboolaKeywords;
