import React, {useState} from "react";
import axios from "axios";
import {Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import {toast} from "react-toastify";
import SearchIcon from '@mui/icons-material/Search';
import {countries_google} from "./countries";
import {
  column_as_number,
  column_as_number_2_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {DataGridPremium} from "@mui/x-data-grid-premium";

const GoogleKeywords = () => {
  const [searchEngine, setSearchEngine] = useState('semrush');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [load, setLoad] = useState(false);
  const [dataRequest, setDataRequest] = useState({keys: '', cpc: 0, searches: 500, difficulty: 0});
  const [keywords, setKeywords] = useState([]);

  const setData = (name, value) => {
    setDataRequest(prevState => ({
      ...prevState, [name]: value
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const onCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  const onSubmit = () => {
    let url = '<undef>';

    if (searchEngine === 'google') {
      url = `${process.env.REACT_APP_API_URL}/api/keys/google/?keys=${dataRequest.keys}&cpc=${dataRequest.cpc}&searches=${dataRequest.searches}`;
    } else if (searchEngine === 'semrush') {
      const countryParam = selectedCountry ? `&country=${selectedCountry.code}` : '';
      url = `${process.env.REACT_APP_API_URL}/api/keys/semrush/?phrase=${dataRequest.keys}&search_volume=${dataRequest.searches}${countryParam}&difficulty=${dataRequest.difficulty}`;
    }

    setLoad(true);
    axios.get(url, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setKeywords(response.data.data);
          setIsDataLoaded(true);
        } else {
          console.log(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columnsGoogle = [
    {field: "keyword", headerName: "Keyword", flex: 0.3, cellClassName: "name-column--cell"},
    {field: "competition", headerName: "Competition", flex: 0.1, ...column_as_number_2_digits},
    {field: "average cpc", headerName: "Average CPC", flex: 0.1, ...column_as_number_2_digits},
    {field: "min cpc", headerName: "Min CPC", flex: 0.1, ...column_as_number_2_digits},
    {field: "max cpc", headerName: "Max CPC", flex: 0.1, ...column_as_number_2_digits},
    {field: "monthly searches", headerName: "Searches on month", flex: 0.1, ...column_as_number},
  ];

  const columnsSemrush = [
    {field: "keyword", headerName: "Keyword", flex: 0.3},
    {field: "search_volume", headerName: "Search Volume", flex: 0.2, ...column_as_number},
    {field: "cpc", headerName: "CPC", flex: 0.1, ...column_as_number_2_digits},
    {field: "competition", headerName: "Competition", flex: 0.1, ...column_as_number_2_digits},
    {field: "difficulty", headerName: "Keyword Difficulty, %", flex: 0.1, ...column_as_number},
  ];

  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    pagination: true,
    pageSizeOptions: [100],
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', padding: 0}}>
      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', alignItems: 'center', gap: 2, marginTop: '15px'}}>
        <FormControl sx={{width: '120px'}}>
          <InputLabel id="search-engine-select-label">Search Engine</InputLabel>
          <Select
            label="Search Engine"
            size="small"
            value={searchEngine}
            onChange={(event) => setSearchEngine(event.target.value)}
          >
            <MenuItem value="google" disabled>Google</MenuItem>
            <MenuItem value="semrush">Semrush</MenuItem>
          </Select>
        </FormControl>

        {searchEngine === 'google' && (
          <>
            <TextField
              sx={{width: '300px'}}
              required
              label="Keywords separated by comma"
              size="small"
              variant="outlined"
              onChange={(event) => setData('keys', event.target.value)}
              onKeyPress={handleKeyPress}
            />
            <TextField
              sx={{width: '300px'}}
              label="Min CPC in Google"
              size="small"
              variant="outlined"
              onChange={(event) => setData('cpc', event.target.value)}
            />
            <TextField
              sx={{width: '200px'}}
              label="Searches on month (default: 500)"
              size="small"
              variant="outlined"
              onChange={(event) => setData('searches', event.target.value)}
            />
          </>
        )}

        {searchEngine === 'semrush' && (
          <>
            <TextField
              sx={{width: '300px'}}
              required
              label="Keywords separated by comma"
              size="small"
              variant="outlined"
              onChange={(event) => setData('keys', event.target.value)}
              onKeyPress={handleKeyPress}
            />
            <TextField
              sx={{width: '200px'}}
              label="Searches on month (default: 500)"
              size="small"
              variant="outlined"
              onChange={(event) => setData('searches', event.target.value)}
            />
            <Autocomplete
              sx={{width: '225px'}}
              size="small"
              options={countries_google}
              getOptionLabel={(option) => option.name}
              onChange={onCountryChange}
              renderInput={(params) => (
                <TextField {...params} label="Country (default: US)" variant="outlined"/>
              )}
            />
            <Tooltip
              title="Enter the maximum percentage value of keyword difficulty. Choose a value between 1 and 100."
              placement="top">
              <TextField
                sx={{width: '160px'}}
                label="Keyword Difficulty, %"
                size="small"
                variant="outlined"
                type="number"
                InputProps={{inputProps: {min: 1, max: 100}}}
                onChange={(event) => setData('difficulty', event.target.value)}
              />
            </Tooltip>
          </>
        )}

        <Button color="secondary" variant="contained" onClick={onSubmit} startIcon={<SearchIcon/>}>
          Search
        </Button>
      </Box>

      <Box sx={{
        display: 'flex', flexDirection: 'column', flex: 1, marginTop: '15px', padding: 0, overflow: 'auto'
      }}>
        {isDataLoaded && searchEngine === 'google' && (
          <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
            <DataGridPremium
              {...commonGridProps}
              rows={keywords}
              columns={columnsGoogle}
              getRowId={(row) => row.keyword}
              sx={{height: "calc(100vh - 276px)"}}
            />
          </Box>
        )}

        {isDataLoaded && searchEngine === 'semrush' && (
          <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
            <DataGridPremium
              {...commonGridProps}
              rows={keywords}
              columns={columnsSemrush}
              getRowId={(row) => row.keyword}
              sx={{height: "calc(100vh - 276px)"}}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GoogleKeywords;
