import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";

import axios from "axios";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";

const RSOCCodeFuelStatistic = ({isAuthenticated, user}) => {
  const [channels, setChannels] = useState([]);
  const [timezones, setTimezones] = useState("LA: 0 - Kyiv: 0");
  const [load, setLoad] = useState(false);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText('RSOCCodeFuelStatistic');
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const navigate = useNavigate();


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_channels()
  }, [startStr, endStr, selectedEmployee, searchText]);


  const get_channels = () => {
    setLoad(true)

    axios.get(`${process.env.REACT_APP_API_URL}/api/rsoc/codefuel/last_update/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          let la_last_update = response.data['la_last_update'];
          let kiev_last_update = response.data['kiev_last_update'];
          let timezones = "[last updates] LA: " + la_last_update + " - Kyiv: " + kiev_last_update;
          setTimezones(timezones)
        } else {
          console.log(response.statusText)
        }

      })
      .catch(function (error) {
        console.log(error);
      })


    axios.get(`${process.env.REACT_APP_API_URL}/api/rsoc/codefuel/statistic/?start_date=${startStr}&end_date=${endStr}&search=${searchText}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setChannels(response.data);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  }

  const columns = [
    {
      field: "channel",
      headerName: "Channel",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "ad_clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "rpc",
      headerName: "RPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    // {
    //   field: "sessions",
    //   headerName: "Sessions",
    //   flex: 0.1,
    //   ...column_as_number,
    // },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="RSOC Statistic" subtitle={timezones}/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath="RSOCCodeFuelStatistic"
          onChange={(value) => {
            setSearchText(value);
          }}
          inputProps={{sx: {height: '36px', padding: '0 14px'}}}
          sx={{height: '36px', maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_channels()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={channels}
          columns={columns}
          getRowId={(row) => row.channel}
          initialState={{
            aggregation: {
              model: {
                revenue: 'sum',
                ad_clicks: 'sum',
                sessions: 'sum',
              },
            },
          }}
          density="compact"
          hideFooterSelectedRowCount={true}
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(RSOCCodeFuelStatistic);
