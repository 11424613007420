import React, {useEffect, useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import {toast} from "react-toastify";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalNewDomain = ({open, handleClose, handleRefresh}) => {
  const [domain, setDomain] = useState('');
  const [account, setAccount] = useState('');
  const [accounts, setAccounts] = useState([]);

  const styleModal = {
    ...getModalCommonStyle(),
    width: 420,
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/accounts/`, requestConfig)
      .then(response => {
        if (response.status === 200) {
          setAccounts(response.data.map(acc => ({id: acc, value: acc})));
        } else {
          console.log(response.statusText);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setDomain('');
    setAccount('');
  }, [open]);

  const onSubmit = () => {
    const data = {
      domain: domain,
      account: account
    };

    axios.post(`${process.env.REACT_APP_API_URL}/api/ppads/domains/`, data, requestConfig)
      .then(response => {
        if (response.status === 201) {
          toast.success('Domain created successfully', {
            position: "top-center",
            autoClose: 3000
          });
          handleRefresh();
          handleClose();
        } else {
          toast.error('Failed to create domain');
        }
      })
      .catch(error => {
        console.log(error);
        toast.error(formatErrorMessage(error));
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      backdrop="static"
    >
      <Box sx={styleModal}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
          <Header title="Add Domain" subtitle="add new domain in ADS"/>

          <IconButton onClick={handleClose} sx={{position: "absolute", top: 15, right: 15,}}>
            <CloseIcon/>
          </IconButton>
        </Box>

        <TextField
          required
          label="Domain Name"
          variant="outlined"
          sx={{width: 350, marginTop: 3}}
          onChange={(event) => setDomain(event.target.value)}
          value={domain}
        />

        <Autocomplete
          sx={{width: 350, marginTop: 3}}
          disableClearable
          options={accounts}
          getOptionLabel={(option) => option.value}
          onChange={(event, values) => setAccount(values.id)}
          renderInput={(params) => <TextField {...params} label="Choose an account"/>}
          value={accounts.find(item => item.id === account) || null}
        />

        <Box display="flex" justifyContent="start" mt="50px">
          <Button color="secondary" variant="contained" onClick={onSubmit}>
            Add Domain
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalNewDomain;
