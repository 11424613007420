import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import axios from 'axios';

import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import Header from "../../components/Header";

import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {useActiveTabHistory} from '../../hocs/ActiveTabHistory';


const TonicOneCampaignStatistic = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;

  const [campaigns, setCampaigns] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const [titleCampaign, setTitleCampaign] = useState("");
  const [sortModelCampaigns, setSortModelCampaigns] = useState([]);
  const [sortModelKeywords, setSortModelKeywords] = useState([]);

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useActiveTabHistory('tab_campaigns', TonicOneCampaignStatistic.name);
  const [load, setLoad] = useState(false)


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_campaigns()
  }, [activeTab, startStr, endStr, selectedEmployee]);


  const get_campaigns = () => {
    if (activeTab === 'tab_campaigns') {
      axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/${cid}/`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setTitleCampaign(response.data.campaign_name)
          } else {
            console.log(response.statusText)
          }
        })
        .catch(function (error) {
          console.log(error);
        })

      setLoad(true)
      axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/statistic_by_date/?campaign=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.length > 0) {
              setCampaigns(response.data)
            } else {
              setCampaigns([])
            }
          } else {
            console.log(response.statusText)
          }
          setLoad(false)
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }

    if (activeTab === 'tab_keywords') {
      setLoad(true)
      axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/statistic_keywords/?campaign=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.length > 0) {
              setKeywords(response.data)
            } else {
              setKeywords([])
            }
          } else {
            console.log(response.statusText)
          }
          setLoad(false)
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }
  }

  const columns_campaigns = [
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
    },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      ...column_as_number_2_digits,
    },
  ]

  const columns_keywords = [
    {
      field: "keyword",
      headerName: "Keyword",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "epc",
      headerName: "EPC",
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      ...column_as_number_2_digits,
    },
  ]

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }

    setActiveTab(newValue);

    if (newValue !== 'tab_campaigns') {
      setCampaigns([]);
    }
    if (newValue !== 'tab_keywords') {
      setKeywords([]);
    }
  };


  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Campaign ${titleCampaign}`} subtitle="statistic by campaign"/>

      <Box m="0 0 20px 0">
        <Link to={`/`} onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{marginTop: '10px'}}
          >
            <Tab value="tab_campaigns" label="Total statistic"/>
            <Tab value="tab_keywords" label="Statistic by keywords"/>
          </Tabs>

          <TabPanel value="tab_campaigns"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={campaigns}
              columns={columns_campaigns}
              getRowId={(row) => row.date}
              onSortModelChange={(newModel) => {
                setSortModelCampaigns(newModel);
              }}
              initialState={{
                aggregation: {
                  model: {
                    profit: 'sum',
                    roi: 'avg',
                    spend: 'sum',
                    clicks: 'sum',
                    visits: 'sum',
                    revenue: 'sum',
                    epc: 'avg',
                  },
                },
                sorting: {
                  sortModel: sortModelCampaigns,
                },
              }}
            />
          </TabPanel>

          <TabPanel value="tab_keywords"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={keywords}
              columns={columns_keywords}
              getRowId={(row) => row.keyword}
              onSortModelChange={(newModel) => {
                setSortModelKeywords(newModel);
              }}
              initialState={{
                aggregation: {
                  model: {
                    clicks: 'sum',
                    revenue: 'sum',
                    epc: 'avg',
                  },
                },
                sorting: {
                  sortModel: sortModelKeywords,
                },
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TonicOneCampaignStatistic);
