import React from "react";
import Topbar from "../components/TopBar";


const Layout = (props) => {

  return (
    <div className="container h-100 pt-3 col-lg">
      <Topbar/>
      {props.children}
    </div>
  );
};

export default Layout;
