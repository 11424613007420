import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import axios from 'axios';

import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";

import {column_as_number, getDataGridCommonStyle, ModalCircularProgress, requestConfig} from "../utils";


const RedTrackStatistic = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;

  const [titleCampaign, setTitleCampaign] = useState("");
  const [offers, setOffers] = useState([]);

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const navigate = useNavigate();
  const [load, setLoad] = useState(false)


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  if (!isAuthenticated) {
    navigate("/")
  }

  useEffect(() => {
    get_offers()
  }, [startStr, endStr, selectedEmployee]);


  const get_offers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/campaigns/${cid}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setTitleCampaign(response.data.title)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/statistic/?campaign_id=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setOffers(response.data)
        } else {
          console.log(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      })
  };

  const columns_offer = [
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "offer_title",
      headerName: "Offer Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      type: "number",
      headerAlign: "left",
      align: "left",
      ...column_as_number,
    },
    {
      field: "conversions",
      headerName: "Conversions",
      type: "number",
      headerAlign: "left",
      align: "left",
      ...column_as_number,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Campaign ${titleCampaign}`} subtitle="statistic by offers"/>

      <Box m="0 0 20px 0">
        <Link to="/" onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          density="compact"
          hideFooterSelectedRowCount
          rows={offers}
          columns={columns_offer}
          getRowId={(row) => row.date + ' - ' + row.offer_id}
          initialState={{
            aggregation: {
              model: {
                clicks: 'sum',
                conversions: 'sum',
              },
            },
          }}
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  )
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(RedTrackStatistic);
