import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
import { LineChartComponent } from "./LineChart";

const StatBox = ({ title, icon, date=[], data=[] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  

  const show_chart = () => {
    {if (date.length !== 0 && data.length !== 0) {
      return <LineChartComponent date={date} data={data} />
    }}
  }

  return (
    <Box width="100%" m="15px 30px 10px">
      <Box display="flex" justifyContent="space-between">
        <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={2}
        >
          <Grid item xs={2}>
            {icon}
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>

        
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={2}
        >
          {show_chart()}
        </Grid>
      </Box>
    </Box>
  );
};

export default StatBox;