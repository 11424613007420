import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Header from "../../components/Header";
import axios from "axios";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import trashBinIcon from './icons/trashBinIcon.png';
import {ModalCircularProgress, requestConfig} from "../utils";
import {toast} from "react-toastify";

const convertKeysToCamelCase = (obj) => {
  const snakeToCamel = (str) => {
    return str.replace(/(_\w)/g, match => match[1].toUpperCase());
  };

  const convertKey = (key) => {
    if (key === 'facebook_access_token') {
      return 'facebookAccessTokenId';
    }
    return snakeToCamel(key);
  };

  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = convertKey(key);
      acc[newKey] = convertKeysToCamelCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

const FacebookUserTokens = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const [facebookUsers, setFacebookUsers] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      setLoad(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/user/`, requestConfig);
        const camelCaseResponse = convertKeysToCamelCase(response.data);
        setFacebookUsers(camelCaseResponse);
      } catch (error) {
        console.error('Error fetching user tokens:', error);
      } finally {
        setLoad(false);
      }
    };

    fetchData();
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      const handleAuthCallback = async () => {
        setLoad(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/facebook/user/`, {code}, requestConfig);

          if (response.status === 200) {
            navigate('/facebook/adaccounts');
          }
        } catch (error) {
          setLoad(false);
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An unexpected error occurred. Please try again.');
          }
          setTimeout(() => {
            navigate('/facebook/user/tokens');
          }, 3000); // Redirect after 3 seconds
        }
      };

      handleAuthCallback();
    }
  }, [navigate]);

  const handleAddNewAccount = () => {
    window.location.href = (
      `https://www.facebook.com/v20.0/dialog/oauth?response_type=code%20granted_scopes` +
      `&client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/facebook/callback_refresh/` +
      `&scope=ads_read&state=clickid`
    );
  };

  const handleButtonClick = async (facebookAccountId, facebookAccessTokenId, tokenStatus) => {
    try {
      if (tokenStatus === "active") {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/facebook/token/${facebookAccessTokenId}/disconnect/`,
          {},
          requestConfig
        );
        toast.success('Account disconnected successfully.');
        setFacebookUsers(prevUsers => prevUsers.map(user =>
          user.facebookAccountId === facebookAccountId
            ? {...user, tokenStatus: 'inactive'}
            : user
        ));
      } else if (tokenStatus === "inactive" || tokenStatus === null) {
        window.location.href = (
          `https://www.facebook.com/v20.0/dialog/oauth?response_type=code%20granted_scopes` +
          `&client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/facebook/callback_refresh/` +
          `&scope=ads_read&state=clickid&state=${facebookAccountId},${facebookAccessTokenId}`
        );
      }
    } catch (error) {
      console.error('Error handling button click:', error);
    }
  };

  const handleDeleteAccount = async () => {
    if (selectedUser) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/facebook/user/${selectedUser.facebookAccountId}/`,
          requestConfig
        );
        toast.success(`Account with ID ${selectedUser.facebookAccountId} deleted successfully.`);
        setFacebookUsers(prevUsers => prevUsers.filter(user => user.facebookAccountId !== selectedUser.facebookAccountId));
        setShowDialog(false);
        setSelectedUser(null);
      } catch (error) {
        console.error('Error deleting account:', error);
        toast.error('An unexpected error occurred. Please try again.');
        setShowDialog(false);
        setSelectedUser(null);
      }
    }
  };

  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedUser(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case null:
        return '#399b82'; // Set color to green for empty status
      case 'inactive':
        return '#ce894c'; // Set color to yellow for inactive status
      case 'active':
        return '#ce4c4c'; // Set color to red for active status
      default:
        return 'defaultColor'; // Set default color if status doesn't match
    }
  };

  return (
    <Box style={{maxWidth: 'max-content'}}>
      <ModalCircularProgress load={load}/>

      <Box m="6px 0 0 0">
        <Link to={`/`} onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back to
          FB</Link>
      </Box>

      <Header title="Facebook User Accounts"/>

      {facebookUsers.length > 0 ? (
        facebookUsers.map((facebookUser, index) => (
          <Box key={index} display="flex" alignItems="center" justifyContent="space-between" m="20px 0">
            <Box display="flex" alignItems="center">
              <FontAwesomeIcon
                icon={facebookUser.tokenStatus === 'active' ? faCheckCircle : faTimesCircle}
                style={{
                  color: facebookUser.tokenStatus === 'active' ? 'green' : 'red',
                  marginRight: '10px'
                }}
              />
              <p style={{marginBottom: '0'}}>
                {facebookUser.firstName} {facebookUser.lastName}
              </p>
            </Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: getStatusColor(facebookUser.tokenStatus),
                width: '200px',
                color: '#e5e1de',
                marginLeft: '20px',
              }}
              onClick={() => handleButtonClick(
                facebookUser.facebookAccountId,
                facebookUser.facebookAccessTokenId,
                facebookUser.tokenStatus
              )}
            >
              {facebookUser.tokenStatus === null
                ? `Connect Facebook`
                : facebookUser.tokenStatus === 'inactive'
                  ? `Connect again`
                  : `Disconnect Facebook`}
            </Button>
            <Button
              onClick={() => handleOpenDialog(facebookUser)}
              style={{color: 'red'}}
            >
              <img src={trashBinIcon} alt="Trash Bin Icon" style={{width: '24px', height: '24px'}}/>
            </Button>
          </Box>
        ))
      ) : (
        <Box m="20px 0">
          <p>No connected accounts.</p>
        </Box>
      )}

      <hr style={{border: 'none', borderBottom: '1px solid white', margin: '20px 0'}}/>
      <Box>
        <Button
          variant="contained"
          style={{color: '#ffffff'}}
          onClick={handleAddNewAccount}
        >
          Add New Facebook Account
        </Button>
      </Box>

      <Dialog
        open={showDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Account Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete the account: {selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(FacebookUserTokens);
