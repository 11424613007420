import {Box, Button, Typography} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import { requestConfig } from "../utils";

const PixelActivate = (props) => {
  const onActivate = () => {
    let url;
    let data = {
      "pixel_id": props.pixel,
      "access_token": props.token
    };
    if (props.alias === "facebook") {
      url = `${process.env.REACT_APP_API_URL}/api/facebook/pixel/`;
    } else if (props.alias === "tiktok") {
      url = `${process.env.REACT_APP_API_URL}/api/tiktok/pixel/`;
    }

    axios.post(url, data, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          toast.success(
            'Pixel activated successfully!', {position: "top-center", autoClose: 3000}
          );  // AND REFRESH THIS PAGE
        } else {
          toast.error(response.data);
        }
        props.get_campaigns();
      })
      .catch(function (error) {
        toast.error(error.response.statusText);
      });
  };

  return (
    <Box>
      {props.is_active ? (
        <Typography variant="body1"><b>Activated</b></Typography>
      ) : (
        <Box m="20px 0 20px 0">
          <Button variant="outlined" onClick={onActivate}>Activate</Button>
        </Box>
      )}
    </Box>
  );
};

export default PixelActivate;
