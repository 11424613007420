import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {searchClearText, SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";


const ZemantaAdvertiser = ({isAuthenticated, user}) => {
  const navigate = useNavigate();
  const searchStorage = ZemantaAdvertiser.name
  const [load, setLoad] = useState(false)

  const [advertisers, setAdvertisers] = useState([])

  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })
  const [pinnedRows, setPinnedRows] = useState({
    bottom: [{id: 'total', visits: "", clicks: "", revenue: "0,00", spend: "0,00", profit: "0,00",}]
  })
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_campaigns();
  }, [paginationModel, sortModel]);

  useEffect(() => {
    searchClearText('ZemantaCampaigns');
  }, []);


  const get_campaigns = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/zemanta/statistic_by_advertiser/?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        const {results, count, totals} = response.data;
        setAdvertisers(results);
        setRowCount(count)
        setPinnedRows({
          bottom: [{
            id: 'total',
            visits: "",
            clicks: "",
            revenue: totals.revenue,
            spend: totals.spend,
            profit: totals.profit,
          }]
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const getRowId = (row) => {
    if (row.id === 'total') {
      return 'total'; // A unique ID for the total row
    }
    return row.advertiser_id; // Assuming domain_name is unique for each regular row
  };

  const columns = [
    {
      field: "advertiser_name",
      headerName: "Advertiser Name",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/zemanta/advertiser/${params.row.advertiser_id}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "conversion",
      headerName: "Conversion",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "cpa",
      headerName: "CPA",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Zemanta" subtitle="statistic by advertisers"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={advertisers}
          columns={columns}
          getRowId={getRowId}
          rowCount={rowCount}
          pinnedRows={pinnedRows}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          hideFooterSelectedRowCount
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />

      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(ZemantaAdvertiser);
