import React, {useEffect} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import GoogleKeywords from './GoogleKeywords';
import TaboolaKeywords from './TaboolaKeywords';
import Header from "../../components/Header";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useActiveTabHistory} from '../../hocs/ActiveTabHistory';

const KeywordsSearch = ({isAuthenticated, user}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useActiveTabHistory('google', KeywordsSearch.name);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="Keywords" subtitle="Keywords Search"/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', margin: '-5px 0 0 0'}}>
        <TabContext value={activeTab}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Google" value="google"/>
            <Tab label="Taboola" value="taboola"/>
          </Tabs>

          <TabPanel value="google"
                    sx={{
                      display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px', padding: 0
                    }}>
            <GoogleKeywords/>
          </TabPanel>

          <TabPanel value="taboola"
                    sx={{
                      display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px', padding: 0
                    }}>
            <TaboolaKeywords/>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(KeywordsSearch);
