import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import axios from "axios";
import { useTheme} from '@mui/material';
import { toast } from "react-toastify";
import {requestConfig} from "../utils";

import { tokens } from "../../theme";

const TikTokConnect = ({ isAuthenticated }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {sendCode()}, [searchParams])

    // TicTok Connect
    const sendCode = () => {
      console.log(searchParams.get('auth_code'))
      if (searchParams.get('auth_code')) {
        axios.get(`${process.env.REACT_APP_API_URL}/api/tiktok/connect/?code=${searchParams.get('auth_code')}`, requestConfig)
          .then(function (response) {
            if (response.status === 200) {
              toast.success("Success connect")
            }
            if (response.status === 204) {
              toast.error(response.data ? response.data : 'Invalid verification code format')
            }
          })
          .catch(function (error) {
              console.log(error);
          })
        navigate("/tonic/campaigns")
      }
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TikTokConnect); 