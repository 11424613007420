import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {connect, useSelector} from 'react-redux';
import {Box, Button} from "@mui/material";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {convertSortModelToDjangoSort, getDataGridCommonStyle, ModalCircularProgress, requestConfig} from "../utils";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import ModalKeywords from "./ModalKeywords";
import {activeTabHistoryClear} from "../../hocs/ActiveTabHistory";


const CodeFuelCampaigns = ({isAuthenticated, account}) => {
  const navigate = useNavigate();
  const searchStorage = CodeFuelCampaigns.name + account;
  const [load, setLoad] = useState(false);

  const [campaigns, setCampaigns] = useState([]);

  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    activeTabHistoryClear('CodeFuelOneCampaignStatistic');
  }, []);

  useEffect(() => {
    setPaginationModel(prev => ({...prev, page: 0}));
  }, [searchText, selectedEmployee]);

  useEffect(() => {
    get_campaigns();
  }, [paginationModel, sortModel]);


  const get_campaigns = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');

    setLoad(true);
    axios.get(`${process.env.REACT_APP_API_URL}/api/codefuel/campaigns/?account=${account}&page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          const {results, count} = response.data;
          setCampaigns(results);
          setRowCount(count);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setLoad(false);
      });
  };

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.9,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/codefuel/statistic/${account}/${params.row.id}`}>{params.value}</Link>
        );
      },
    },
    {
      field: "_keywords",
      align: 'center',
      headerName: "Keywords",
      headerAlign: 'center',
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.campaign_id && params.row.campaign_id !== '') {
          return (
            <ModalKeywords
              id={params.row.id}
              campaign_name={params.row.campaign_name}
            />
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={account.charAt(0).toUpperCase() + account.slice(1).toLowerCase() + ' 2'}
              subtitle="list of campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 15px 0', minHeight: '38px', width: '100%'}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value);
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <DataGridPremium
          rows={campaigns}
          columns={columns}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          density="compact"
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    account: props.account || "UNDEF",
  };
};

export default connect(mapStateToProps)(CodeFuelCampaigns);
