import {Box, CircularProgress, useTheme} from "@mui/material";
import {tokens} from "../theme";
import React from "react";

let requestConfig = {
  get headers() {
    return {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
      'Accept': 'application/json',
      'MasqueradeUser': localStorage.getItem('masqueradeUser'),
      'MasqueradeTeam': localStorage.getItem('masqueradeTeam')
    };
  }
};

const formatErrorMessage = (error) => {
  const renderMessages = (messages) => {
    if (Array.isArray(messages)) {
      return messages.join(', ');
    } else if (typeof messages === 'object') {
      return Object.values(messages).join(', ');
    } else {
      return messages;
    }
  }

  try {
    return (
      <div>
        {error.message && <div style={{color: 'red'}}>Error: {error.message}</div>}
        <ul>
          {error.response && error.response.data && typeof error.response.data === 'object' &&
            Object.entries(error.response.data).map(([field, messages]) => (
              <li key={field}>
                <strong>{field}:</strong> {renderMessages(messages)}
              </li>
            ))}
        </ul>
      </div>
    )
  } catch (e) {
    return (
      <div>{error.message || 'Error!'}</div>
    )
  }
}

const column_as_number = {
  align: "right",
  headerAlign: "right",
  type: "number",
  valueFormatter: (params) => {
    if (params.value !== null && !isNaN(params.value)) {
      return params.value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
    } else {
      return params.value;
    }
  },
}

const column_as_number_2_digits = {
  align: "right",
  headerAlign: "right",
  type: "number",
  valueFormatter: (params) => {
    if (params.value !== null && !isNaN(params.value)) {
      return params.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    } else {
      return params.value;
    }
  },
}

const column_as_number_3_digits = {
  align: "right",
  headerAlign: "right",
  type: "number",
  valueFormatter: (params) => {
    if (params.value !== null && !isNaN(params.value)) {
      return params.value.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3});
    } else {
      return params.value;
    }
  },
}

const convertSortModelToDjangoSort = (sortModel) => {
  return sortModel.map((sort) => {
    const djangoSortOrder = sort.sort === 'asc' ? '' : '-';
    return `${djangoSortOrder}${sort.field}`;
  });
};

const getModalCommonStyle = () => {
  return {
    position: 'absolute',
    top: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 450,
    maxHeight: 'calc(100vh - 60px)',
    overflowY: 'auto',
    backgroundColor: 'background.paper',
    border: '2px solid #ffffff38',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    padding: '32px',
    paddingTop: 0,
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };
}

const getDataGridCommonStyle = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return {
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .name-column--cell": {
      color: colors.greenAccent[300],
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.blueAccent[700],
      borderBottom: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: colors.blueAccent[700],
      justifyContent: "center",
      minHeight: "39px",
    },
    "& .MuiDataGrid-footerContainer p": {
      marginBottom: 0,
    },
    "& .MuiTablePagination-actions button": {
      padding: "6px",
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "39px",
    },
    "& .MuiCheckbox-root": {
      color: `${colors.greenAccent[200]} !important`,
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
      color: `${colors.grey[100]} !important`,
    },
    "& .MuiDataGrid-footerCell": {
      color: "rgb(112 216 189)",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-pinnedRowsRenderZone": {
      color: "rgb(112 216 189)",
      fontWeight: "bold",
    },
    "& .MuiTabPanel-root": {
      padding: '0',
    },
    scrollbarColor: 'rgba(100, 100, 100, 0.3) rgba(0, 0, 0, 0)',
  }
}

const ModalCircularProgress = ({load}) => {
  if (!load) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress/>
    </Box>
  );
};

export {
  requestConfig,
  formatErrorMessage,
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  convertSortModelToDjangoSort,
  getModalCommonStyle,
  getDataGridCommonStyle,
  ModalCircularProgress,
};
